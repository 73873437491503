import Head from 'next/head';
import type { PageCustomProps } from '@pages/_app';
import Listing from '@customers/employees-profile/listing';

const EmployeesPage: PageCustomProps = () => {
  return (
    <>
      <Head>
        <title>Employees</title>
      </Head>
      <>
        <Listing />
      </>
    </>
  );
};

EmployeesPage.pageTitle = 'Employees';

export default EmployeesPage;
