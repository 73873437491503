import { useState, useMemo } from 'react';
import { Column } from 'react-table';
import Table from '@swvl/table';
import Tag from '@swvl/tag';
import { IconButton } from '@swvl/button';
import { EditIcon } from '@swvl/icons';
import { Select } from '@swvl/select';
import { useTheme } from '@swvl/theme';

import { pluralize } from '@utils';
import { useEmployeesList, useGroups } from './resources';
import { EmptyState } from './empty-state';
import { ROUTING_STATUS } from '@customers/employees-profile/constants';
import { EmployeeDrawer } from '@customers/employees-profile/drawer';
import { useEmployees } from '@context/employees';
import SearchInput from '@shared/SearchInput';
import { SelectProps } from '@customers/employees-profile/types';
import { useApp } from '@context/app';
import { Employee } from './types';

import Link from 'next/link';
import { useRouter } from 'next/router';

export const EmployeesTab = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [group, setGroup] = useState<SelectProps>(null);
  const [stationAssign, setStationAssign] = useState<SelectProps>(null);
  const [searchQuery, setSearchQuery] = useState<string>(null);

  const { sidebarWidth } = useApp();
  const { theme } = useTheme();
  const { data, isLoading } = useEmployeesList({
    page: currentPage + 1,
    limit: pageSize,
    groupId: group?.value,
    stationAssign: stationAssign?.value,
    searchQuery,
  });
  const { data: groupsData } = useGroups({
    limit: Number.MAX_SAFE_INTEGER,
  });
  const { handleEditEmployeeDrawer } = useEmployees();

  // Handlers
  const handleEditEmployee = (employee: Employee) => {
    handleEditEmployeeDrawer(true, employee);
  };

  // Router
  const router = useRouter();

  const columns = useMemo(
    () =>
      [
        {
          Header: 'Employee name',
          accessor: 'name',
          Cell: ({ value, row }) => {
            return value ? (
              <Link
                passHref
                href={{
                  pathname: `/customers/employees/[employeeId]`,
                  query: {
                    ...router.query,
                    employeeId: row.original.id,
                  },
                }}
              >
                <span
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '&:hover': {
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {value}
                </span>
              </Link>
            ) : null;
          },
        },
        {
          Header: 'Phone number',
          accessor: 'phone',
        },
        {
          Header: 'Email',
          accessor: 'email',
        },
        {
          Header: 'Employee group',
          accessor: 'group',
        },
        {
          Header: 'Status',
          accessor: 'status',
          Cell: ({ value }) => (
            <Tag variant={value ? 'success-light' : 'negative-light'}>{value ? 'Registered' : 'Unregistered'}</Tag>
          ),
        },
        {
          Header: 'Pickup station',
          accessor: 'pickupStation',
          Cell: ({ value }) => (
            <div
              title={value}
              sx={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
            >
              {value}
            </div>
          ),
        },
        {
          Header: 'Dropoff station',
          accessor: 'dropoffStation',
          Cell: ({ value }) => (
            <div
              title={value}
              sx={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
            >
              {value}
            </div>
          ),
        },
        {
          Header: 'Meeting Pickup station',
          accessor: 'meetingPickupStation',
          Cell: ({ value }) => (
            <div
              title={value}
              sx={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
            >
              {value}
            </div>
          ),
        },
        {
          Header: 'Meeting Dropoff station',
          accessor: 'meetingDropoffStation',
          Cell: ({ value }) => (
            <div
              title={value}
              sx={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
            >
              {value}
            </div>
          ),
        },
        {
          Header: 'Action',
          accessor: 'actions',
          disableSortBy: true,
          Cell: ({ value }) => (
            <div sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <IconButton
                onClick={() => handleEditEmployee(value)}
                type="button"
                title="Edit Employee"
                icon={<EditIcon />}
              />
            </div>
          ),
        },
      ] as Column<typeof tableData[number]>[],
    [],
  );

  const tableData =
    data?.hits?.map(it => ({
      id: it.id,
      name: it.name,
      phone: it.phone,
      email: it.email,
      group: it.group?.name,
      status: it.isAppRegistered,
      pickupStation: it.pickupStation?.name,
      dropoffStation: it.dropoffStation?.name,
      meetingPickupStation: it.meetingPickupStation?.name,
      meetingDropoffStation: it.meetingDropoffStation?.name,
      actions: it,
    })) || [];

  const groupsDropDownData = groupsData?.hits.map(ele => ({ value: ele.id, label: ele.name })) || [];
  const paginationConfig = {
    totalCount: data?.total,
    initialPageIndex: currentPage,
    initialPageSize: pageSize,
    manualPagination: true,
    onPageChange: setCurrentPage,
    onPageSizeChange: setPageSize,
  };

  const TableRightFilter = () => {
    const stationAssignments = [
      { label: 'All', value: null },
      { label: 'Assigned', value: true },
      { label: 'Un-assigned', value: false },
    ];
    return (
      <div sx={{ display: 'flex', gap: 'spacing-s' }}>
        <div
          sx={{
            width: '230px',
          }}
        >
          <Select
            id="select-station"
            name="station"
            isClearable
            isSearchable={false}
            options={stationAssignments}
            value={stationAssign}
            onChange={option => {
              const selected = option as SelectProps;
              setStationAssign(selected);
              setCurrentPage(0);
            }}
            placeholder={'Station assignment - All'}
            data-test-name="employees-filter-station"
            data-test-value={stationAssign?.value}
          />
        </div>
        <div
          sx={{
            width: '220px',
          }}
        >
          <Select
            id="select-group"
            name="group"
            isClearable
            isSearchable={false}
            options={[{ value: false, label: 'Unassigned' }, ...groupsDropDownData]}
            value={group}
            onChange={option => {
              const selected = option as SelectProps;
              setGroup(selected);
              setCurrentPage(0);
            }}
            placeholder={'Employee group'}
            data-test-name="employees-filter-group"
            data-test-value={group?.value}
          />
        </div>
        <div
          sx={{
            width: '290px',
          }}
        >
          <SearchInput handleChange={text => setSearchQuery(text)} placeholder="Search by name or phone " />
        </div>
      </div>
    );
  };

  return (
    <>
      <div sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mr: '3px' }}>
        <h2 sx={{ variant: 'text.p-large-bold', my: '21px' }}>{pluralize(data?.total ?? 0, 'Employee')}</h2>
        {TableRightFilter()}
      </div>
      <div
        sx={{
          width: `calc(100vw - ${sidebarWidth} - ${theme.space['spacing-l']})`,
          transition: 'width 0.4s',
        }}
      >
        <Table
          data={tableData}
          columns={columns}
          fullWidth={true}
          sameSizeCells={false}
          paginationConfig={paginationConfig}
          enableSorting
          isLoading={isLoading}
          noDataComponent={<EmptyState activeTab={ROUTING_STATUS.EMPLOYEES} />}
          getRowId={row => row.id}
          getRowProps={(props, row) => ({
            ...props,
            'data-test-name': 'employee-profile-row',
            'data-test-id': row?.id,
          })}
          data-test-name="employee-profile-table"
        />
      </div>
      <EmployeeDrawer />
    </>
  );
};
