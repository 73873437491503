import { AxiosResponse } from 'axios';
import camelCase from 'camelcase-keys';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { showAlert } from '@swvl/alert';
import { useCustomers } from '@context/customers';
import { getErrorMessage } from '@utils';
import { API } from '@utils/api-config';

// Types
export type Gender = 'male' | 'female' | 'other';

type Employee = {
  _id: string;
  name: string;
  number_of_companions: number;
  email: string;
  corporate: string;
  employeeId: string;
  id: string;
  gender: Gender;
  pickup: {
    address: string;
    coords: {
      lat: number;
      lng: number;
    };
    updatedAt: Date;
  };
  exclusion_reason?: string;
  active: boolean;
  createdAt: string;
  deleted: boolean;
  phone: string;
  phone_region_code: string;
  is_app_registered: boolean;
  group: {
    name: string;
    id: string;
  };
  pickup_station: {
    name: string;
    id: string;
  };
  dropoff_station: {
    _id: string;
    name: string;
  };
  meeting_pickup_station: {
    name: string;
    id: string;
  };
  meeting_dropoff_station: {
    _id: string;
    name: string;
  };
  user: string;
  updatedAt: string;
};

type Group = {
  _id: string;
  name: string;
  business_profiles_count: number;
  description: string;
};

export type APIEmployeeResponse = {
  type: string;
  hits: Employee[];
  total: number;
};
export type APIGroupsResponse = {
  type: string;
  hits: Group[];
  total: number;
};
export type APIGroupResponse = {
  _id: string;
  name: string;
  description: string;
};
type QueryParamsEmployee = {
  page?: number;
  limit?: number;
  date?: Date;
  corporateId?: string;
  groupId?: string;
  stationAssign?: string;
  searchQuery?: string;
};

type QueryParamsGroup = {
  page?: number;
  name?: string;
  limit?: number;
  corporateId?: string;
  groupId?: string;
};

export type Employees = ReturnType<typeof employeesDataTransformer>;
export type Groups = ReturnType<typeof groupsDataTransformer>;

export type GroupAutocompleteOptions = { query: string; corporateId?: string };

export type GroupAutocomplete = {
  _id: string;
  _source: {
    name: string;
  };
};

type GroupAutocompleteAPIResponse = {
  hits: GroupAutocomplete[];
  total: number;
};

// Transforms
const employeesDataTransformer = (data: APIEmployeeResponse) => camelCase(data, { deep: true });
const groupsDataTransformer = (data: APIGroupsResponse) => camelCase(data, { deep: true });
const groupDataTransformer = (data: APIGroupResponse) => camelCase(data, { deep: true });
const groupAutocompleteDataTransformer = (data: GroupAutocompleteAPIResponse) =>
  camelCase(
    {
      ...data,
      hits: data.hits.map(item => ({ ...item, ...item._source })),
    },
    { deep: true },
  );
const employeeDataTransformer = (data: Employee) => camelCase(data, { deep: true });

// Resources
const fetchEmployees = async ({
  corporateId,
  page,
  limit,
  groupId,
  stationAssign,
  searchQuery,
}: QueryParamsEmployee) => {
  const params = {
    page,
    limit,
    group: groupId,
    pickup_station: stationAssign,
    search_query: searchQuery,
  };

  const { data } = await API.get<APIEmployeeResponse>(`corporate/${corporateId}/profiles`, {
    params,
  });
  return employeesDataTransformer(data);
};

// Groups APIs
const fetchGroups = async ({ corporateId, page, limit, groupId }: QueryParamsEmployee) => {
  const params = {
    page,
    limit,
    _id: groupId,
  };
  const { data } = await API.get<APIGroupsResponse>(`/corporate/${corporateId}/employee_groups`, {
    params,
  });
  return groupsDataTransformer(data);
};
const deleteGroup = async ({ corporateId, groupId }: QueryParamsGroup) => {
  const { data } = await API.delete<APIGroupResponse>(`/corporate/${corporateId}/employee_groups/${groupId}`);
  return groupDataTransformer(data);
};
const autocompleteGroup = async ({ query, corporateId }: GroupAutocompleteOptions) => {
  const { data } = await API.get<GroupAutocompleteAPIResponse>(
    `corporate/${corporateId}/employee_groups/autocomplete?query=${query}`,
  );

  return groupAutocompleteDataTransformer(data);
};

export const fetchEmployee = async (employeeId: string, corporateId: string) => {
  const { data } = await API.get<Employee>(`/corporate/${corporateId}/profiles/${employeeId}`);
  return employeeDataTransformer(data);
};

// Hooks
export const useEmployeesList = ({ page = 1, limit = 10, ...filters }: QueryParamsEmployee) => {
  const { selectedCorporateId } = useCustomers();
  const corporateId = selectedCorporateId ?? '';

  return useQuery(
    ['employees', [page, limit, selectedCorporateId, filters.groupId, filters.stationAssign, filters.searchQuery]],
    () => fetchEmployees({ page, limit, corporateId, ...filters }),
    {
      enabled: !!corporateId,
    },
  );
};

export const useGroups = ({ page = 1, limit = 10, groupId }: QueryParamsGroup) => {
  const { selectedCorporateId } = useCustomers();

  const corporateId = selectedCorporateId ?? '';

  return useQuery(
    ['groups', [page, limit, groupId, selectedCorporateId]],
    () => fetchGroups({ page, limit, corporateId, groupId }),
    {
      enabled: !!corporateId,
    },
  );
};

export const useDeleteGroup = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteGroup, {
    mutationKey: 'group/delete',
    onSuccess: () => {
      showAlert({
        message: 'Group was deleted successfully',
        id: 'alert-success',
        variant: 'success',
        mode: 'dark',
      });
      queryClient.invalidateQueries('groups');
      queryClient.invalidateQueries('employees');
    },
    onError: (response: AxiosResponse) => {
      const message = getErrorMessage(response);
      showAlert({
        message,
        id: 'alert-error',
        variant: 'error',
        mode: 'dark',
      });
    },
  });
};
export const useAutocompleteGroup = ({ query }: GroupAutocompleteOptions) => {
  const { selectedCorporateId } = useCustomers();

  const corporateId = selectedCorporateId ?? '';
  return useQuery(['group/autocomplete', query, selectedCorporateId], () => autocompleteGroup({ query, corporateId }));
};

export const useEmployeeDetails = (employeeId: string) => {
  const { selectedCorporateId } = useCustomers();
  const corporateId = selectedCorporateId ?? '';

  return useQuery(['employee', [employeeId, corporateId]], () => fetchEmployee(employeeId, corporateId));
};

//bulk-upload-employees
export const employeesBulkAction = async ({
  corporateId,
  action,
  document,
}: {
  corporateId: string;
  action: string;
  document: FormData;
}) => {
  const response = await API.post(`corporate/${corporateId}/bulk_action?action=${action}`, document);
  return response;
};

export const useBulkUpdateEmployees = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(employeesBulkAction, {
    mutationKey: 'employees/update',
    onSuccess: () => {
      showAlert({
        message: 'You have updated the employees successfully',
        type: 'success',
        id: 'alert-success',
      });
      queryClient.invalidateQueries('employees');
    },
    onError: (response: AxiosResponse) => {
      const message = getErrorMessage(response);
      showAlert({
        message,
        type: 'error',
        id: 'alert-error',
      });
    },
  });
  return mutation;
};

//get-list-of-stations
// Types: station
export type StationResponse = {
  data: {
    _id: string;
    name: string;
  };
};

// Transforms
const stationDataTransformer = ({ data }: StationResponse) => camelCase(data, { deep: true });

// Requests: get-station
export const fetchStation = async (stationId: string) => {
  const data = await API.get<AxiosResponse, StationResponse>(`station/${stationId}`);
  return stationDataTransformer(data);
};

// Types: update-employee
export type UpdateEmployeeRequestBody = {
  corporateId: string;
  name: string;
  email: string;
  gender: string;
  employee_id: string;
  can_use_private_ride: boolean;
  userId: string;
  number_of_companions: number;
  group_id?: string;
  station_id_to_office?: string;
  station_id_from_office?: string;
  meeting_station_id_to_office?: string;
  meeting_station_id_from_office?: string;
};

//edit-employee
// Requests: update-employee
export const updateEmployee = async ({
  corporateId,
  name,
  gender,
  email,
  employee_id,
  can_use_private_ride,
  userId,
  group_id,
  number_of_companions,
  station_id_to_office,
  station_id_from_office,
  meeting_station_id_to_office,
  meeting_station_id_from_office,
}: UpdateEmployeeRequestBody) => {
  const response = await API.patch(`corporate/${corporateId}/profiles/${userId}`, {
    group_id,
    name,
    gender,
    email,
    employee_id,
    number_of_companions,
    can_use_private_ride,
    station_id_to_office: station_id_to_office || undefined,
    station_id_from_office: station_id_from_office || undefined,
    meeting_station_id_to_office: meeting_station_id_to_office || undefined,
    meeting_station_id_from_office: meeting_station_id_from_office || undefined,
  });
  return response;
};

// Custom Hook: update-employee
export const useUpdateEmployee = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateEmployee, {
    mutationKey: 'employee/update',
    onSuccess: () => {
      showAlert({
        message: 'You have updated the employee successfully',
        type: 'success',
        id: 'alert-success',
      });
      queryClient.invalidateQueries('employees');
    },
    onError: error => {
      showAlert({
        message: (error as { data: { message: string } })['data']?.message,
        type: 'error',
        id: 'alert-error',
      });
    },
  });

  return mutation;
};

// Types - add-employee
export interface AddEmployee {
  name: string;
  phone_obj: { number: string; region_code: string };
  phone: string;
  phone_region_code: string;
  gender?: Gender;
  employee_id?: string;
  group_id?: string;
  email?: string;
  pickup_station_id?: string;
  dropoff_station_id?: string;
  meeting_pickup_station_id?: string;
  meeting_dropoff_station_id?: string;
  number_of_companions?: number;
}

// Transformer: add-employee
const addEmployeeTransformer = (form: Partial<AddEmployee>) => {
  const tempObject = {} as Partial<AddEmployee>;

  let key: keyof Partial<AddEmployee>;
  for (key in form) {
    const value = form[key] as Gender & { number: string; region_code: string } & number;

    if (value) {
      tempObject[key] = value;
    }
  }
  return tempObject;
};

// Requests: add-employee
const addEmployee = async ({ corporateId, ...form }: Partial<AddEmployee> & { corporateId: string }) => {
  const data = addEmployeeTransformer(form);
  const response = await API.post<AddEmployee>(`corporate/${corporateId}/profiles`, data);
  return response;
};

// Custom Hook: add-employee
export const useAddEmployee = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(addEmployee, {
    mutationKey: 'employee/create',
    onSuccess: () => {
      queryClient.invalidateQueries('employees');
      showAlert({
        message: 'You have added an Employee successfully',
        type: 'success',
        id: 'alert-success',
      });
    },
    onError: (response: AxiosResponse) => {
      const message = getErrorMessage(response);
      showAlert({
        message,
        type: 'error',
        id: 'alert-error',
      });
    },
  });
  return mutation;
};
