import { useMutation, useQueryClient } from 'react-query';
import { API } from '@utils/api-config';
import { showAlert } from '@swvl/alert';
import { FormData } from './types';
import { instrumentationsHandler } from '@utils/instrumentations';
import { getErrorMessage } from '@utils';
import { AxiosResponse } from 'axios';

// Types - create-group
export interface GroupProps {
  _id?: string;
  id?: string;
  name: string;
  description?: string;
  data?: GroupProps;
}

// Requests: create-group
const createGroup = async ({ corporateId, ...data }: FormData & { corporateId: string }) => {
  const response = await API.post<GroupProps, GroupProps>(`corporate/${corporateId}/employee_groups`, data);
  return response;
};
const updateGroup = async ({ corporateId, id, ...data }: FormData & { corporateId: string }) => {
  const response = await API.patch<GroupProps, GroupProps>(`corporate/${corporateId}/employee_groups/${id}`, data);
  return response;
};

// Custom Hook: group
const useCreateGroup = ({ onSuccessCb }: { onSuccessCb: () => void }) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(createGroup, {
    mutationKey: 'group/create',
    onSuccess: res => {
      queryClient.invalidateQueries('groups');
      showAlert({
        message: 'You have added a new group successfully',
        type: 'success',
        id: 'alert-success',
      });
      instrumentationsHandler('create_group_status', { group_name: res.data.name, status: 'success' });
      onSuccessCb();
    },
    onError: (response: AxiosResponse) => {
      const message = getErrorMessage(response);
      instrumentationsHandler('create_group_status', { status: 'failure' });
      showAlert({
        message,
        type: 'error',
        id: 'alert-error',
      });
    },
  });
  return mutation;
};

const useUpdateGroup = ({ onSettledCb }: { onSettledCb: () => void }) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(updateGroup, {
    mutationKey: 'group/update',
    onSuccess: () => {
      queryClient.invalidateQueries('groups');
      showAlert({
        message: 'You have updated the group successfully',
        type: 'success',
        id: 'alert-success',
      });
    },
    onError: (response: AxiosResponse) => {
      const message = getErrorMessage(response);
      showAlert({
        message,
        type: 'error',
        id: 'alert-error',
      });
    },
    onSettled: () => {
      onSettledCb();
    },
  });
  return mutation;
};

export { useCreateGroup, createGroup, useUpdateGroup, updateGroup };
