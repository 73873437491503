import { showAlert } from '@swvl/alert';
import { getErrorMessage } from '@utils';
import axios, { AxiosResponse } from 'axios';
import camelCase from 'camelcase-keys';
import { unset } from 'lodash';
import snakeCase from 'lodash/snakeCase';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API } from '@utils/api-config';

// Types

type CsvTemplateAPIResponse = {
  mandatory_fields: string[];
  fields: string[];
  sample_data_exist: boolean;
};

type BulkAction = {
  _id: string;
  __t: string;
  user: { _id: string; name: string; type: string };
  number_of_records: number;
  number_of_successful_records: number;
  number_of_failed_records: number;
  file_name: string;
  created_at: string;
  updated_at: string;
  __v: number;
  final_report: string;
  completed: number;
  pending: number;
  status: string;
  description: string;
};

export type RedHulkAction = {
  actionName: string;
  actionAlias: string;
  componentName: string;
  componentDescription: string;
  requestEndPoint: string;
  requestEndPointMethod: string;
  csvTemplateHeaders: number;
  csvRequiredHeaders: string;
  queryParams: string;
  pathParams: string;
  body: string;
  headers: string;
  sampleData: string;
};

type PerformableBulkAction = {
  id: string;
  action: string;
  component_name: string;
  error: boolean;
  message: string;
  action_name: string;
  description: string;
};

type PerformableBulkActionsAPIResponse = {
  list: PerformableBulkAction[];
  total: number;
};
type BulkActionReportAPIResponse = {
  url: string;
};

type ListBulkActionsAPIResponse = {
  hits: BulkAction[];
  total: number;
};

type FetchAllBulkActions = {
  action?: string;
  name?: string;
  userId?: string;
  page?: number;
  limit?: number;
};

type GeneratePreSignedUrlResponse = {
  url: string;
  fileName: string;
};

// Transforms
const csvTemplateDataTransformer = (data: CsvTemplateAPIResponse) => camelCase(data, { deep: true });
const listBulkActionsDataTransformer = (data: ListBulkActionsAPIResponse) => camelCase(data, { deep: true });
const bulkActionReportDataTransformer = (data: BulkActionReportAPIResponse) => camelCase(data, { deep: true });
const performableActionReportDataTransformer = (data: PerformableBulkActionsAPIResponse) =>
  camelCase(data, { deep: true });

// Resources

const downloadCsvTemplate = async ({ action, usingNewService }: { action: string; usingNewService?: boolean }) => {
  const url = usingNewService ? `/bulk_actions/template` : `/bulk_action/template`;
  const { data } = await API.get<CsvTemplateAPIResponse>(url, {
    params: { action },
  });
  return csvTemplateDataTransformer(data);
};

const fetchAllBulkActions = async ({
  action,
  userId,
  page,
  limit,
  usingNewService,
}: FetchAllBulkActions & { usingNewService?: boolean }) => {
  const url = usingNewService ? `/bulk_actions/list_actions` : `/bulk_action`;
  const params = { action, userId, page, limit };
  const { data } = await API.get<ListBulkActionsAPIResponse>(url, { params });
  return listBulkActionsDataTransformer(data);
};

const fetchBulkActionReport = async ({ id, action }: { id: string; action: string }) => {
  const params = { action };
  const { data } = await API.get<BulkActionReportAPIResponse>(`/bulk_action/${id}/file_url`, { params });
  return bulkActionReportDataTransformer(data);
};

const fetchPerformableBulkActions = async ({
  page,
  limit,
  usingNewService,
}: FetchAllBulkActions & { usingNewService?: boolean }) => {
  const url = usingNewService ? `/bulk_actions/supported_actions` : `/bulk_action/types`;
  const params = { page, limit };
  const { data } = await API.get<PerformableBulkActionsAPIResponse>(url, { params });
  return performableActionReportDataTransformer(data);
};

const creatBulkAction = async ({ formData, action }: { formData: FormData; action: string }) => {
  const params = { action };
  const response = await API.post<any>(`/bulk_action`, formData, { params });
  return response;
};

// Hooks

export const createBulkActionV2 = async (body: any) => {
  const response = await API.post<any>(`/bulk_actions/supported_actions`, body);
  return response;
};

export const editBulkActionV2 = async (body: any) => {
  const id = body.id;
  unset(body, 'id');
  const response = await API.put<any>(`/bulk_actions/supported_actions?id=${id}`, body);
  return response;
};

export const deleteBulkActionV2 = async (id: any) => {
  const response = await API.delete<any>(`/bulk_actions/supported_actions?id=${id}`);
  return response;
};

export const useCsvTemplate = ({ action, usingNewService }: { action: string; usingNewService?: boolean }) => {
  return useQuery(['csv-template', action, usingNewService], () => downloadCsvTemplate({ action, usingNewService }), {
    enabled: !!action,
  });
};

export const useListBulkActions = ({
  action,
  userId,
  page,
  limit,
  usingNewService,
}: FetchAllBulkActions & { usingNewService?: boolean }) => {
  return useQuery(
    ['old-bulk-actions', action, userId, page, limit, usingNewService],
    () => fetchAllBulkActions({ action, userId, page, limit, usingNewService }),
    { refetchInterval: 10000 },
  );
};

export const useBulkActionReport = ({ id, action }: { id: string; action: string }) => {
  // changing any camelcase values for action query parameter to snakecase
  action = snakeCase(action);
  return useQuery(['old-bulk-action-report', id, action], () => fetchBulkActionReport({ id, action }), {
    enabled: !!action || !!id,
  });
};

export const usePerformableBulkActions = ({
  page,
  limit,
  usingNewService,
}: FetchAllBulkActions & { usingNewService?: boolean }) => {
  return useQuery(['old-bulk-actions/types', page, limit, usingNewService], () =>
    fetchPerformableBulkActions({ page, limit, usingNewService }),
  );
};

export const useCreateBulkAction = ({ onSuccessCb }: { onSuccessCb: () => void }) => {
  const mutation = useMutation(creatBulkAction, {
    mutationKey: 'old-bulk-action/create',
    onSuccess: () => {
      showAlert({
        id: 'old-bulk-action-create',
        message: 'You have successfully started a bulk action. View the status in the dashboard page.',
        type: 'success',
        mode: 'dark',
      });
      onSuccessCb();
    },
    onError: (response: AxiosResponse) => {
      const message = getErrorMessage(response);
      showAlert({
        id: 'old-bulk-action-create',
        message,
        type: 'error',
        mode: 'dark',
      });
    },
  });

  return mutation;
};

// Resources New Bulk Action Service

const processNewBulkAction = async ({
  fileName,
  action,
  userEmail,
  userName,
}: {
  fileName: string;
  action: string;
  userEmail: string;
  userName: string;
}) => {
  const params = { fileName, action };
  const payload = { userEmail, userName };
  const response = await API.post<any>(`/bulk_actions/upload/process`, payload, { params });
  return response;
};

const generatePreSignedUrl = async ({ fileName }: { fileName: string }) => {
  const params = { fileName };
  const { data } = await API.get<GeneratePreSignedUrlResponse>(`/bulk_actions/upload/url`, { params });
  return data;
};

const bulkActionDetails = async (id: string) => {
  const { data } = await API.get<GeneratePreSignedUrlResponse>(`/bulk_actions/supported_actions/details/${id}`);
  return data;
};

const uploadToPreSignedUrl = async ({ presignedUrl, file }: { presignedUrl: string; file: File }) => {
  const response = await axios.put<any>(presignedUrl, file, {
    headers: {
      'Content-Type': file.type,
    },
  });
  return response;
};

// Hooks

export const useProcessNewBulkAction = ({ onSuccessCb }: { onSuccessCb: () => void }) => {
  const mutation = useMutation(processNewBulkAction, {
    mutationKey: 'bulk-action/process',
    onSuccess: () => {
      showAlert({
        id: 'bulk-action-process',
        message: 'You have successfully started a bulk action. View the status in the dashboard page.',
        type: 'success',
        mode: 'dark',
      });
      onSuccessCb();
    },
    onError: (response: AxiosResponse) => {
      const message = getErrorMessage(response);
      showAlert({
        id: 'bulk-action-process',
        message,
        type: 'error',
        mode: 'dark',
      });
    },
  });

  return mutation;
};

export const useCreateBulkActionV2 = ({
  onSuccessCb,
  onErrorCb,
}: {
  onSuccessCb: () => void;
  onErrorCb: () => void;
}) => {
  const mutation = useMutation(createBulkActionV2, {
    mutationKey: 'bulk-action/create',
    onSuccess: () => {
      showAlert({
        id: 'bulk-action-create',
        message: 'You have successfully created a bulk action',
        type: 'success',
        mode: 'dark',
      });
      onSuccessCb();
    },
    onError: (response: AxiosResponse) => {
      showAlert({
        id: 'bulk-action-create',
        message: response.data.error,
        type: 'error',
        mode: 'dark',
      });
      onErrorCb();
    },
  });

  return mutation;
};

export const useEditBulkActionV2 = ({ onSuccessCb, onErrorCb }: { onSuccessCb: () => void; onErrorCb: () => void }) => {
  const mutation = useMutation(editBulkActionV2, {
    mutationKey: 'bulk-action/update',
    onSuccess: () => {
      showAlert({
        id: 'bulk-action-update',
        message: 'You have successfully updated a bulk action',
        type: 'success',
        mode: 'dark',
      });
      onSuccessCb();
    },
    onError: (response: AxiosResponse) => {
      showAlert({
        id: 'bulk-action-update',
        message: response.data.error,
        type: 'error',
        mode: 'dark',
      });
      onErrorCb();
    },
  });

  return mutation;
};

export const useDeleteBulkActionV2 = ({
  onSuccessCb,
  onErrorCb,
}: {
  onSuccessCb: () => void;
  onErrorCb: () => void;
}) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(deleteBulkActionV2, {
    mutationKey: 'bulk-action/delete',
    onSuccess: () => {
      showAlert({
        id: 'bulk-action-delete',
        message: 'Bulk action deleted successfully',
        type: 'success',
        mode: 'dark',
      });
      queryClient.invalidateQueries('old-bulk-actions/types');
      queryClient.removeQueries('old-bulk-actions/types');
      onSuccessCb();
    },
    onError: (response: AxiosResponse) => {
      showAlert({
        id: 'bulk-action-delete',
        message: response.data.error,
        type: 'error',
        mode: 'dark',
      });
      onErrorCb();
    },
  });

  return mutation;
};

export const useUploadToPreSignedUrl = ({ onSuccessCb }: { onSuccessCb: () => void }) => {
  const mutation = useMutation(uploadToPreSignedUrl, {
    mutationKey: 'bulk-action/upload-to-presigned-url',
    onSuccess: () => {
      showAlert({
        id: 'bulk-action-csv-upload',
        message: 'You have successfully uploaded a bulk action',
        type: 'success',
        mode: 'dark',
      });
      onSuccessCb();
    },
    onError: (response: AxiosResponse) => {
      const message = getErrorMessage(response);
      showAlert({
        id: 'bulk-action-csv-upload',
        message,
        type: 'error',
        mode: 'dark',
      });
    },
  });

  return mutation;
};

export const useGeneratePreSignedUrl = ({ fileName }: { fileName: string }) => {
  return useQuery(['bulk-actions/get-presigned-url', fileName], () => generatePreSignedUrl({ fileName }));
};

export const useBulkActionDetails = (id: string): any => {
  return useQuery(['bulk-actions-details/'], () => bulkActionDetails(id), { enabled: !!id });
};
