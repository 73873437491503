import { useEffect, useRef } from 'react';

export function useDebounce(callback: (args: unknown) => void, timeout: number, deps: Array<unknown>) {
  const timeoutId = useRef(null);

  useEffect(() => {
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(callback, timeout);
    return () => clearTimeout(timeoutId.current);
  }, [...deps]);
}
