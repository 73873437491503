import { useState } from 'react';
import { Formik, Form, FormikHelpers as FormikActions, FormikProps } from 'formik';
import { object, string, number } from 'yup';
import { Button } from '@swvl/button';
import { PersonAddIcon } from '@swvl/icons';
import TabsWrapper, { TabWrapper } from '@swvl/tabs';
import { useTheme } from '@swvl/theme';
import { useCustomers } from '@context/customers';
import { useEmployees } from '@context/employees';

import LocationInformation from './location-information-tab';
import PersonalInformation from './personal-information-tab';
import { useAddEmployee, AddEmployee } from './resources';

import 'react-phone-input-2/lib/high-res.css';

// Validation
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const validationSchema = object().shape({
  name: string().required('Name is required'),
  phone_obj: object().shape({
    number: string().required('Mobile Number is required').min(7).matches(phoneRegExp, 'Mobile number is not valid'),
    region_code: string().required('Mobile Number is required'),
  }),
  number_of_companions: number().min(0, 'Number must be positive').nullable(),
});

// Components
const Header = () => {
  return (
    <div>
      <header
        sx={{
          m: 'spacing-m',
          mb: 'spacing-m-l',
        }}
      >
        <div sx={{ display: 'flex' }}>
          <PersonAddIcon sx={{ marginInlineEnd: 'spacing-xs' }} width={32} height={32} />
          <h5 sx={{ variant: 'text.h5', m: 0 }}>Add new employee</h5>
        </div>
      </header>
    </div>
  );
};

const AddEmployeeForm = () => {
  // Resources
  const { mutate: addEmployee } = useAddEmployee();

  // Contexts
  const { theme } = useTheme();
  const [activeKey, setActiveKey] = useState<number>(1);
  const { selectedCorporateId } = useCustomers();

  const [formValues] = useState<AddEmployee>({
    name: '',
    phone_obj: { number: '', region_code: '' },
    phone: '',
    phone_region_code: '',
    gender: 'other',
    employee_id: '',
    number_of_companions: undefined,
    group_id: '',
    email: '',
    pickup_station_id: '',
    dropoff_station_id: '',
  });

  const [isPickUpStationExist, setIsPickUpStationExist] = useState(true);
  const [isDropOffStationExist, setIsDropOffStationExist] = useState(true);
  const [isMeetingPickUpStationExist, setIsMeetingPickUpStationExist] = useState(true);
  const [isMeetingDropOffStationExist, setIsMeetingDropOffStationExist] = useState(true);

  //validation
  const validateStations = (values: AddEmployee) => {
    const errors: {
      pickup_station_id?: string;
      dropoff_station_id?: string;
      meeting_pickup_station_id?: string;
      meeting_dropoff_station_id?: string;
    } | null = {};
    if (values.pickup_station_id?.length < 24 && values.pickup_station_id !== '') {
      errors.pickup_station_id = 'You have to enter 24 characters';
    }
    if (!isPickUpStationExist && values.pickup_station_id?.length >= 24) {
      errors.pickup_station_id = 'Station does not exist. Please assign a valid  pickup station ID';
    }
    if (values.dropoff_station_id?.length < 24 && values.dropoff_station_id !== '') {
      errors.dropoff_station_id = 'You have to enter 24 characters';
    }
    if (!isDropOffStationExist && values.dropoff_station_id?.length >= 24) {
      errors.dropoff_station_id = 'Station does not exist. Please assign a valid drop-off station ID';
    }
    // meeting stations validation
    if (values.meeting_pickup_station_id?.length < 24 && values.meeting_pickup_station_id !== '') {
      errors.meeting_pickup_station_id = 'You have to enter 24 characters';
    }
    if (!isMeetingPickUpStationExist && values.meeting_pickup_station_id?.length >= 24) {
      errors.meeting_pickup_station_id = 'Station does not exist. Please assign a valid meeting pickup station ID';
    }
    if (values.meeting_dropoff_station_id?.length < 24 && values.meeting_dropoff_station_id !== '') {
      errors.meeting_dropoff_station_id = 'You have to enter 24 characters';
    }
    if (!isMeetingDropOffStationExist && values.meeting_dropoff_station_id?.length >= 24) {
      errors.meeting_dropoff_station_id = 'Station does not exist. Please assign a valid meeting drop-off station ID';
    }
    return errors;
  };

  const next = () => {
    setActiveKey(activeKey + 1);
  };
  const { handleAddEmployeeDrawer } = useEmployees();

  const onSubmitHandler = (values: AddEmployee, actions: FormikActions<AddEmployee>) => {
    addEmployee(
      {
        corporateId: selectedCorporateId,
        name: values.name,
        phone: values.phone_obj.number,
        phone_region_code: values.phone_obj.region_code,
        gender: values.gender,
        employee_id: values.employee_id,
        group_id: values.group_id,
        number_of_companions: values.number_of_companions,
        email: values.email,
        pickup_station_id: values.pickup_station_id,
        dropoff_station_id: values.dropoff_station_id,
        meeting_pickup_station_id: values.meeting_pickup_station_id,
        meeting_dropoff_station_id: values.meeting_dropoff_station_id,
      },
      {
        onSuccess: () => {
          actions.setSubmitting(false);
          handleAddEmployeeDrawer(false);
        },
        onError: () => {
          actions.setSubmitting(false);
        },
      },
    );
  };
  return (
    <>
      <Header />
      <div
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          minHeight: `calc(100vh - 50px)`,
          position: 'relative',
        }}
      >
        <div
          sx={{
            px: 'spacing-m',
            paddingBottom: 'spacing-m',
          }}
        >
          <div
            sx={{
              width: '100%',
            }}
          >
            <Formik
              initialValues={formValues}
              validationSchema={validationSchema}
              validate={validateStations}
              validateOnBlur={false}
              isInitialValid={false}
              validateOnChange={true}
              enableReinitialize={true}
              onSubmit={(values, actions) => {
                if (activeKey === 2) {
                  onSubmitHandler(values, actions);
                } else {
                  next();
                  actions.setSubmitting(false);
                  actions.setTouched({});
                }
              }}
            >
              {(formikForm: FormikProps<AddEmployee>) => {
                const { isValid, isSubmitting } = formikForm;
                return (
                  <Form
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexWrap: 'nowrap',
                      height: `calc(100vh - 70px)`,
                    }}
                  >
                    <TabsWrapper
                      sx={{
                        overflowY: 'auto',
                        height: '90%',
                      }}
                      tabSize="large"
                      activeKey={`${activeKey}`}
                      onTabClick={key => setActiveKey(Number(key))}
                    >
                      <TabWrapper tab="Personal information" key={'1'}>
                        <PersonalInformation />
                      </TabWrapper>
                      <TabWrapper tab="Location information" key={'2'}>
                        <LocationInformation
                          setIsPickUpStationExist={setIsPickUpStationExist}
                          setIsDropOffStationExist={setIsDropOffStationExist}
                          setIsMeetingPickUpStationExist={setIsMeetingPickUpStationExist}
                          setIsMeetingDropOffStationExist={setIsMeetingDropOffStationExist}
                        />
                      </TabWrapper>
                    </TabsWrapper>
                    <div
                      sx={{
                        boxShadow: `inset 0px 1px 0px ${theme.colors.border}`,
                        backgroundColor: 'white',
                        pt: 'spacing-m',
                        display: 'flex',
                        flexDirection: 'row',
                        minHeight: '80px',
                        justifyContent: 'space-between',
                        width: '91%',
                        position: 'absolute',
                        left: '25px',
                        bottom: '46px',
                      }}
                    >
                      <span sx={{ variant: 'text.p-small-medium', marginInlineEnd: 'spacing-m-l', pt: 'spacing-xs' }}>
                        Step {activeKey} of 2
                      </span>
                      <Button type="submit" size="small" disabled={!isValid || isSubmitting}>
                        {activeKey === 2 ? 'Add employee' : 'Next'}
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEmployeeForm;
