import { useState, ReactElement } from 'react';
import { IconButton } from '@swvl/button';
import { CloseCircleIcon, SearchIcon } from '@swvl/icons';
import Input from '@swvl/input';
import { useDebounce } from '@utils/useDebounce';

type SearchInputProps = {
  handleChange?: (value: string) => void;
  handlePressEnter?: (value: string) => void;
  placeholder?: string;
  showStartEnhancer?: boolean;
  label?: string | ReactElement;
};

const SEARCH_DEBOUNCE_TIME = 1000;

export default function SearchInput({
  handlePressEnter,
  handleChange,
  placeholder,
  showStartEnhancer = true,
  label,
}: SearchInputProps) {
  const [searchText, setSearchText] = useState<string>('');

  const handleDebouncedChange = () => {
    const text = searchText.length >= 3 ? searchText : undefined;
    handleChange?.(text);
  };
  useDebounce(handleDebouncedChange, SEARCH_DEBOUNCE_TIME, [searchText]);

  return (
    <Input
      startEnhancer={showStartEnhancer && <SearchIcon name="search" fill={'rgba(0, 0, 0, 0.5)'} />}
      label={label}
      endEnhancer={
        searchText.length > 0 ? (
          <IconButton
            onClick={() => {
              setSearchText('');
              handlePressEnter?.('');
            }}
            sx={{
              svg: {
                fill: 'content-quarternary',
              },
            }}
            icon={<CloseCircleIcon width={20} height={20} />}
          />
        ) : null
      }
      placeholder={placeholder}
      value={searchText}
      onChange={e => {
        setSearchText(e.target.value);
      }}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          handlePressEnter?.(searchText);
        }
      }}
    />
  );
}
