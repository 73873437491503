import TabsWrapper, { TabWrapper } from '@swvl/tabs';
import { useEmployees } from '@context/employees';
import { ROUTING_STATUS } from '@customers/employees-profile/constants';
import { EmployeesTab } from '@customers/employees-profile/employees-tab';
import { GroupsListing } from '@customers/employees-group/listing';
import { Button } from '@swvl/button';
import { MapIcon, UploadIcon, PersonAddIcon } from '@swvl/icons';
import { instrumentationsHandler } from '@utils/instrumentations';

const Employees = () => {
  const { employeesFilters, setEmployeesFilters, handleGroupDrawer, handleBulkUpdateDrawer, handleAddEmployeeDrawer } =
    useEmployees();

  const handleClickCreateGroup = () => {
    handleGroupDrawer(true);
    instrumentationsHandler('click_add_group');
  };

  return (
    <div>
      <div
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {employeesFilters.activeTab === ROUTING_STATUS.EMPLOYEES ? (
          <>
            <Button
              type="button"
              variant="secondary"
              sx={{ py: 1, px: 12, mt: -56, mx: 16 }}
              icon={<PersonAddIcon sx={{ marginInlineEnd: 'spacing-xs' }} width={24} height={24} />}
              size="medium"
              onClick={() => handleAddEmployeeDrawer(true)}
            >
              Add new
            </Button>
            <Button
              type="button"
              variant="tertiary"
              sx={{ py: 1, px: 12, mt: -56 }}
              icon={<UploadIcon sx={{ marginInlineEnd: 'spacing-xs' }} width={24} height={24} />}
              size="medium"
              onClick={() => handleBulkUpdateDrawer(true)}
            >
              Bulk upload
            </Button>
          </>
        ) : (
          <Button
            type="button"
            sx={{ py: 1, px: 12, mt: -56 }}
            icon={<MapIcon sx={{ marginInlineEnd: 'spacing-xs' }} width={24} height={24} />}
            onClick={handleClickCreateGroup}
          >
            Create group
          </Button>
        )}
      </div>
      <TabsWrapper
        tabSize="large"
        activeKey={employeesFilters?.activeTab}
        onTabClick={tab => {
          setEmployeesFilters({
            activeTab: tab as ROUTING_STATUS,
          });
        }}
      >
        <TabWrapper tab="Employees" key={ROUTING_STATUS.EMPLOYEES}>
          <EmployeesTab />
        </TabWrapper>
        <TabWrapper tab="Groups" key={ROUTING_STATUS.GROUPS}>
          <GroupsListing />
        </TabWrapper>
      </TabsWrapper>
    </div>
  );
};

export default Employees;
