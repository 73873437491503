import { useState, useMemo } from 'react';
import { Column } from 'react-table';
import Table from '@swvl/table';
import { useTheme } from '@swvl/theme';
import Popup, { PopupHeader, PopupContent, PopupFooter } from '@swvl/popup';
import { EditIcon, TrashIcon } from '@swvl/icons';
import { Button, IconButton } from '@swvl/button';
import Autocomplete from '@swvl/autocomplete';
import { pluralize } from '@utils';
import { EmptyState } from '../employees-profile/empty-state';
import { useCustomers } from '@context/customers';
import { CreateEditDrawer } from '@customers/employees-group/drawer';
import { ROUTING_STATUS } from '@customers/employees-profile/constants';
import { useEmployees } from '@context/employees';
import { useGroups, useDeleteGroup, useAutocompleteGroup } from '@customers/employees-profile/resources';
import { GroupProps } from '@customers/employees-group/resource';
import { useApp } from '@context/app';

type PoppedUpGroup = {
  name: string;
  id: string;
};

export const GroupsListing = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [groupsQuery, setGroupsQuery] = useState('');
  const [selectedGroup, setSelectedGroup] = useState<null | string>(null);
  const [pageSize, setPageSize] = useState(10);
  const [isOpen, setOpen] = useState(false);
  const { selectedCorporateId } = useCustomers();
  const { handleGroupDrawer } = useEmployees();
  const { sidebarWidth } = useApp();
  const { theme } = useTheme();

  const [poppedUpGroup, setPoppedUpGroup] = useState<PoppedUpGroup>({ name: '', id: '' });
  const { data, isLoading } = useGroups({
    page: currentPage + 1,
    limit: pageSize,
    groupId: selectedGroup,
  });

  const { mutateAsync: deleteGroup } = useDeleteGroup();

  const columns = useMemo(
    () =>
      [
        {
          Header: 'Group ID',
          accessor: 'id',
        },
        {
          Header: 'Group name',
          accessor: 'name',
        },
        {
          Header: 'Group description',
          accessor: 'description',
          Cell: ({ value }) => (
            <div
              title={value}
              sx={{ width: '250px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
            >
              {value}
            </div>
          ),
        },
        {
          Header: 'Employees',
          accessor: 'number',
        },
        {
          Header: 'Action',
          accessor: 'actions',
          disableSortBy: true,
          Cell: ({ value }) => (
            <div sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <IconButton onClick={() => handleEditGroup(value)} type="button" title="Edit Group" icon={<EditIcon />} />
              <IconButton
                onClick={() => handleClickDelete(value)}
                type="button"
                title="Delete Group"
                icon={<TrashIcon />}
              />
            </div>
          ),
        },
      ] as Column<typeof tableData[number]>[],
    [],
  );

  // Handlers
  const handleClosePopup = () => setOpen(false);
  const handleClickDelete = ({ name, id }: PoppedUpGroup) => {
    setPoppedUpGroup({ name, id });
    setOpen(true);
  };
  const handleDelete = () => {
    handleClosePopup();
    deleteGroup({ groupId: poppedUpGroup.id, corporateId: selectedCorporateId });
  };
  const handleEditGroup = (group: GroupProps) => {
    handleGroupDrawer(true, group);
  };
  const tableData =
    data?.hits?.map(it => ({
      id: it.id,
      name: it.name,
      description: it.description,
      // TODO make this link to employees list
      number: it.businessProfilesCount,
      actions: it,
    })) || [];

  const paginationConfig = {
    totalCount: data?.total,
    initialPageIndex: currentPage,
    initialPageSize: pageSize,
    manualPagination: true,
    onPageChange: setCurrentPage,
    onPageSizeChange: setPageSize,
  };

  const { data: groupsData, isLoading: areGroupsDataLoading } = useAutocompleteGroup({
    query: groupsQuery,
  });

  return (
    <>
      <div>
        <div sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mr: '3px' }}>
          <h2 sx={{ variant: 'text.p-large-bold', my: '21px' }}>{pluralize(data?.total ?? 0, 'Employee Group')}</h2>
          <div sx={{ display: 'flex', gap: 'spacing-s' }}>
            <div
              sx={{
                minWidth: '250px',
              }}
            >
              <Autocomplete
                suggestions={
                  groupsData?.hits?.map(group => ({
                    label: group.name,
                    value: group.id,
                  })) || []
                }
                placeholder="Employee group"
                isLoading={areGroupsDataLoading}
                onChangeQuery={(input: string) => setGroupsQuery(input)}
                onClear={() => setSelectedGroup(null)}
                onOptionSelect={(option: unknown) => setSelectedGroup(option + '')}
              />
            </div>
          </div>
        </div>
        <div
          sx={{
            width: `calc(100vw - ${sidebarWidth} - ${theme.space['spacing-l']})`,
            transition: 'width 0.4s',
          }}
        >
          <Table
            data={tableData}
            columns={columns}
            fullWidth={true}
            sameSizeCells={false}
            paginationConfig={paginationConfig}
            enableSorting
            isLoading={isLoading}
            noDataComponent={<EmptyState activeTab={ROUTING_STATUS.GROUPS} />}
            getRowId={row => row.id}
            getRowProps={(props, row) => ({
              ...props,
              'data-test-name': 'employee-group-row',
              'data-test-id': row?.id,
            })}
            data-test-name="employee-groups-table"
          />
        </div>
      </div>

      <Popup isOpen={isOpen} closePopup={handleClosePopup}>
        <PopupHeader>Delete group</PopupHeader>
        <PopupContent>
          Are you sure you want to delete the group “{poppedUpGroup.name}”? All employees assigned to this group will be
          unlinked.
        </PopupContent>
        <PopupFooter>
          <Button variant="text_link" onClick={handleClosePopup}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleDelete}>
            Delete
          </Button>
        </PopupFooter>
      </Popup>
      <CreateEditDrawer />
    </>
  );
};
