import { useState, useMemo } from 'react';
import { Spinner, Text } from 'theme-ui';
import { Formik, Form, Field, FieldProps, FormikHelpers as FormikActions } from 'formik';
import { object, string, number } from 'yup';

import Toggle from '@swvl/toggle';
import { Button } from '@swvl/button';
import { ButtonGroup, ButtonWrapper } from '@swvl/button-group';
import { useTheme } from '@swvl/theme';
import Input from '@swvl/input';
import { useCustomers } from '@context/customers';
import FieldWrapper from '@shared/field-wrapper';
import { useEmployees } from '@context/employees';
import { EditIcon } from '@swvl/icons';
import { Select } from '@swvl/select';
import { instrumentationsHandler } from '@utils/instrumentations';

import { fetchStation, useUpdateEmployee, useGroups } from './resources';
import { EditEmployeeFormValues } from './types';
import { GENDER } from './constants';

import 'react-phone-input-2/lib/style.css';
//types
type GroupType = { label: string; value: string };

// Validation
export const validationSchema = object().shape({
  name: string().required('Name is required'),
  number_of_companions: number().min(0, 'Number must be positive').nullable(),
});

// Components
const Header = () => {
  return (
    <div>
      <header
        sx={{
          m: 'spacing-m',
        }}
      >
        <div sx={{ display: 'flex', mb: 'spacing-m' }}>
          <EditIcon sx={{ marginInlineEnd: 'spacing-xs' }} width={32} height={32} />
          <h5 sx={{ variant: 'text.h5', m: 0, mb: 'spacing-s' }}>Update employee</h5>
        </div>
      </header>
    </div>
  );
};

const EditEmployeeForm = () => {
  // Contexts
  const { theme } = useTheme();
  const { selectedCorporateId, selectedCorporateData } = useCustomers();
  const { handleEditEmployeeDrawer, selectedEmployee } = useEmployees();
  const { data: groupsData, isLoading: isGroupsLoading } = useGroups({
    page: 1,
    limit: Number.MAX_SAFE_INTEGER,
  });

  const groupsOptions = () => {
    const newGroups = [{ label: 'Unassign', value: 'Unassign' as string }];

    if (groupsData) {
      const existGroups = groupsList;
      newGroups.push(...existGroups);
      return newGroups;
    }
  };

  // Memos
  const groupsList = useMemo(
    () =>
      groupsData?.hits.map(group => ({
        label: group.name,
        value: group.id,
      })),
    [groupsData],
  );

  const initialValues: EditEmployeeFormValues = useMemo(
    () => ({
      id: selectedEmployee?.id,
      employee_id: selectedEmployee?.employeeId,
      gender: selectedEmployee?.gender,
      group: { label: selectedEmployee?.group?.name, value: selectedEmployee?.group?.id },
      name: selectedEmployee?.name,
      number_of_companions: selectedEmployee?.numberOfCompanions ?? 0,
      email: selectedEmployee?.email,
      pickupStationId: selectedEmployee?.pickupStation?.id ?? null,
      pickupStationName: selectedEmployee?.pickupStation?.name ?? null,
      dropoffStationId: selectedEmployee?.dropoffStation?.id ?? null,
      dropoffStationName: selectedEmployee?.dropoffStation?.name ?? null,
      meetingPickupStationId: selectedEmployee?.meetingPickupStation?.id ?? null,
      meetingPickupStationName: selectedEmployee?.meetingPickupStation?.name ?? null,
      meetingDropoffStationId: selectedEmployee?.meetingDropoffStation?.id ?? null,
      meetingDropoffStationName: selectedEmployee?.meetingDropoffStation?.name ?? null,
      phone: selectedEmployee?.phone,
      canUsePrivateRide: selectedEmployee?.canUsePrivateRide ?? false,
    }),
    [
      selectedEmployee?.id,
      selectedEmployee?.employeeId,
      selectedEmployee?.gender,
      selectedEmployee?.group?.name,
      selectedEmployee?.group?.id,
      selectedEmployee?.name,
      selectedEmployee?.numberOfCompanions,
      selectedEmployee?.email,
      selectedEmployee?.pickupStation?.id,
      selectedEmployee?.pickupStation?.name,
      selectedEmployee?.dropoffStation?.id,
      selectedEmployee?.dropoffStation?.name,
      selectedEmployee?.meetingPickupStation?.id,
      selectedEmployee?.meetingPickupStation?.name,
      selectedEmployee?.meetingDropoffStation?.id,
      selectedEmployee?.meetingDropoffStation?.name,
      selectedEmployee?.phone,
      selectedEmployee?.canUsePrivateRide,
    ],
  );
  // states
  const [isPickUpStationExist, setIsPickUpStationExist] = useState(true);
  const [isDropOffStationExist, setIsDropOffStationExist] = useState(true);
  const [isMeetingPickUpStationExist, setIsMeetingPickUpStationExist] = useState(true);
  const [isMeetingDropOffStationExist, setIsMeetingDropOffStationExist] = useState(true);
  const [selectedGender, setSelectedGender] = useState(selectedEmployee?.gender || null);
  // Resources

  const { mutateAsync } = useUpdateEmployee();
  // handlers
  const handleStationIdChange = async (value: string): Promise<{ id: string; name: string }> => {
    try {
      const data = await fetchStation(value);
      return { id: data.id, name: data.name };
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const handleGroup = (groupValue: string) => {
    if (groupValue !== undefined && groupValue === 'Unassign') {
      return null;
    }
    return groupValue;
  };
  const handleCompanion = (companion: number) => {
    if (companion === undefined || !companion) {
      return 0;
    }
    return companion;
  };
  const handleUpdateEmployeeConfirmation = async (
    values: EditEmployeeFormValues,
    actions: FormikActions<EditEmployeeFormValues>,
  ) => {
    const {
      dropoffStationId,
      group,
      pickupStationId,
      name,
      email,
      employee_id,
      gender,
      canUsePrivateRide,
      meetingDropoffStationId,
      meetingPickupStationId,
      number_of_companions,
    } = values;
    try {
      await mutateAsync({
        email,
        name,
        gender,
        employee_id,
        number_of_companions: handleCompanion(number_of_companions),
        corporateId: selectedCorporateId,
        can_use_private_ride: canUsePrivateRide,
        userId: selectedEmployee.id,
        group_id: handleGroup(group?.value),
        station_id_to_office: pickupStationId,
        station_id_from_office: dropoffStationId,
        meeting_station_id_to_office: meetingPickupStationId,
        meeting_station_id_from_office: meetingDropoffStationId,
      });
      handleEditEmployeeDrawer(false, null);
      instrumentationsHandler('action_edit_employee', {
        employee_user_id: selectedEmployee?.id,
        old_pickup_station_id: selectedEmployee?.pickupStation?.id,
        old_emp_group: !selectedEmployee?.group?.id ? 'Unassign' : selectedEmployee?.group?.id,
        new_pickup_station_id: pickupStationId,
        new_drop_off_station_id: dropoffStationId,
        new_emp_group: group?.value,
      });
    } catch (e) {
      console.error(e);
      // stop the submitting action to re-enable the update employee button and remove the spinner
      actions.setSubmitting(false);
    }
  };

  //validation
  const validateGroupStation = (values: EditEmployeeFormValues) => {
    const errors: {
      name?: string;
      pickupStationId?: string;
      dropoffStationId?: string;
      generalError?: string;
      group?: string;
      meetingPickupStationId?: string;
      meetingDropoffStationId?: string;
    } | null = {};
    if (
      (!values.dropoffStationId || values.dropoffStationId === selectedEmployee.dropoffStation?.id) &&
      values.pickupStationId === selectedEmployee.pickupStation?.id &&
      (!values.meetingDropoffStationId ||
        values.meetingDropoffStationId === selectedEmployee.meetingDropoffStation?.id) &&
      (!values.meetingPickupStationId || values.meetingPickupStationId === selectedEmployee.meetingPickupStation?.id) &&
      values?.group?.value === selectedEmployee?.group?.id &&
      values?.name === selectedEmployee?.name &&
      values?.email === selectedEmployee?.email &&
      values?.employee_id === selectedEmployee?.employeeId &&
      values?.gender === selectedEmployee?.gender &&
      values?.canUsePrivateRide === selectedEmployee?.canUsePrivateRide &&
      values?.number_of_companions === selectedEmployee?.numberOfCompanions
    ) {
      errors.generalError = 'You have to change at least one field';
    }
    if (!isPickUpStationExist && values.pickupStationId.length >= 24) {
      errors.pickupStationId = 'Station does not exist. Please assign a valid  pickup station ID';
    }
    if (!isDropOffStationExist && values.dropoffStationId.length >= 24) {
      errors.dropoffStationId = 'Station does not exist. Please assign a valid drop-off station ID';
    }
    if (!isMeetingPickUpStationExist && values.meetingPickupStationId.length >= 24) {
      errors.meetingPickupStationId = 'Station does not exist. Please assign a valid  pickup station ID';
    }
    if (!isMeetingDropOffStationExist && values.meetingDropoffStationId.length >= 24) {
      errors.meetingDropoffStationId = 'Station does not exist. Please assign a valid drop-off station ID';
    }
    return errors;
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={true}
        isInitialValid={false}
        enableReinitialize={true}
        validate={validateGroupStation}
        onSubmit={(values, actions) => {
          actions.validateForm(values);
          actions.setSubmitting(true);
          handleUpdateEmployeeConfirmation(values, actions);
        }}
      >
        {formikForm => (
          <>
            <Header />
            <Form
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                height: `calc(100vh - 85px)`,
                position: 'relative',
              }}
            >
              <div
                sx={{
                  px: 'spacing-m',
                  paddingBottom: 'spacing-m',
                  overflowY: 'auto',
                  height: '90%',
                }}
              >
                <Field name="name">
                  {({ field, meta: { touched, error } }: FieldProps<'name'>) => {
                    return (
                      <FieldWrapper error={touched && error ? error : ''}>
                        <Text variant="p-medium-bold" mb="spacing-xxs">
                          Employee name
                        </Text>
                        <Input
                          onChange={async e => {
                            formikForm.setFieldValue(field.name, e.target.value, false);
                          }}
                          placeholder="Enter Employee Name"
                          {...field}
                          id="name"
                          name="name"
                        />
                      </FieldWrapper>
                    );
                  }}
                </Field>
                <Field name="phone">
                  {({ field, meta: { touched, error } }: FieldProps<'phone'>) => {
                    return (
                      <FieldWrapper error={touched && error ? error : ''}>
                        <Text variant="p-medium-bold" mb="spacing-xxs">
                          Mobile Number
                        </Text>
                        <Input {...field} id="phone" name="phone" disabled />
                      </FieldWrapper>
                    );
                  }}
                </Field>
                <Field name="gender">
                  {({ field, meta: { touched, error } }: FieldProps<'gender'>) => (
                    <FieldWrapper error={touched && error ? error : ''}>
                      <Text variant="p-medium-bold" mb="spacing-xs">
                        Gender
                      </Text>
                      <ButtonGroup>
                        <ButtonWrapper
                          title={GENDER.MALE}
                          type="button"
                          onClick={() => {
                            setSelectedGender(GENDER.MALE);

                            formikForm.setFieldValue(field.name, GENDER.MALE, true);
                          }}
                          sx={{
                            width: '100%',
                          }}
                          active={selectedGender === GENDER.MALE}
                        >
                          {GENDER.MALE}
                        </ButtonWrapper>
                        <ButtonWrapper
                          title={GENDER.FEMALE}
                          type="button"
                          onClick={() => {
                            setSelectedGender(GENDER.FEMALE);
                            formikForm.setFieldValue(field.name, GENDER.FEMALE, true);
                          }}
                          sx={{
                            width: '100%',
                          }}
                          active={selectedGender === GENDER.FEMALE}
                        >
                          {GENDER.FEMALE}
                        </ButtonWrapper>
                      </ButtonGroup>
                    </FieldWrapper>
                  )}
                </Field>
                <Field name="employee_id">
                  {({ field, meta: { touched, error } }: FieldProps<'employee_id'>) => {
                    return (
                      <FieldWrapper error={touched && error ? error : ''}>
                        <Text variant="p-medium-bold" mb="spacing-xxs">
                          Employee id
                        </Text>
                        <Input
                          onChange={async e => {
                            formikForm.setFieldValue('employee_id', e.target.value, false);
                          }}
                          placeholder="Enter Employee id"
                          {...field}
                          id="employee_id"
                          name="employee_id"
                        />
                      </FieldWrapper>
                    );
                  }}
                </Field>
                <Field name="email">
                  {({ field, meta: { touched, error } }: FieldProps<'email'>) => {
                    return (
                      <FieldWrapper error={touched && error ? error : ''}>
                        <Text variant="p-medium-bold" mb="spacing-xxs">
                          Employee Email
                        </Text>
                        <Input
                          placeholder="Enter Employee Email"
                          onChange={async e => {
                            formikForm.setFieldValue('email', e.target.value, false);
                          }}
                          {...field}
                          id="email"
                          name="email"
                        />
                      </FieldWrapper>
                    );
                  }}
                </Field>
                {selectedCorporateData?.privateRideEnabled && (
                  <Field name="canUsePrivateRide">
                    {({ field, meta: { touched, error } }: FieldProps<'canUsePrivateRide'>) => {
                      return (
                        <FieldWrapper error={touched && error ? error : ''}>
                          <Text variant="p-medium-bold" mb="spacing-xxs">
                            Subscribed services
                          </Text>
                          <div sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span sx={{ variant: 'text.p-small', fontWeight: '500', flex: '0 0 252px' }}>
                              Private Ride
                            </span>
                            <div sx={{ alignItems: 'flex-end' }}>
                              <Toggle
                                data-test-value={Boolean(field.value)}
                                id={field.name}
                                checked={Boolean(field.value)}
                                setChecked={value => {
                                  formikForm.setFieldValue('canUsePrivateRide', value);
                                }}
                              />
                            </div>
                          </div>
                        </FieldWrapper>
                      );
                    }}
                  </Field>
                )}
                {/* stations */}
                <Field name="pickupStationId">
                  {({ field, meta: { touched, error } }: FieldProps<'pickupStationId'>) => {
                    return (
                      <FieldWrapper error={touched && error ? error : ''}>
                        <Text variant="p-medium-bold" mb="spacing-xxs">
                          Pickup Station ID
                        </Text>
                        <Input
                          {...field}
                          minLength={24}
                          maxLength={24}
                          name="pickupStationId"
                          placeholder="Enter Pickup station ID"
                          onChange={async e => {
                            formikForm.handleChange(e);
                            if (e.target.value.length === 24) {
                              setIsDropOffStationExist(true);
                              const selectedStation = await handleStationIdChange(e.target.value);
                              if (selectedStation) {
                                setIsDropOffStationExist(true);
                                formikForm.setFieldValue('pickupStationName', selectedStation?.name, true);
                                formikForm.setFieldTouched('pickupStationName', true);
                              } else {
                                setIsPickUpStationExist(false);
                                formikForm.setFieldValue('pickupStationName', '', true);
                                formikForm.setFieldTouched('pickupStationName', true);
                              }
                            } else {
                              formikForm.setFieldValue('pickupStationName', '', true);
                              formikForm.setFieldTouched('pickupStationName', true);
                            }
                          }}
                        />
                      </FieldWrapper>
                    );
                  }}
                </Field>
                <Field name="pickupStationName">
                  {({ field, meta: { touched, error } }: FieldProps<'pickupStationName'>) => {
                    return (
                      <FieldWrapper error={touched && error ? error : ''}>
                        <Text variant="p-medium-bold" mb="spacing-xxs">
                          Pickup Station name
                        </Text>
                        <Input {...field} name="pickupStationName" disabled />
                      </FieldWrapper>
                    );
                  }}
                </Field>
                <Field name="dropoffStationId">
                  {({ field, meta: { touched, error } }: FieldProps<'dropoffStationId'>) => {
                    return (
                      <FieldWrapper error={touched && error ? error : ''}>
                        <Text variant="p-medium-bold" mb="spacing-xxs">
                          Dropoff Station ID
                        </Text>
                        <Input
                          {...field}
                          minLength={24}
                          maxLength={24}
                          name="dropoffStationId"
                          placeholder="Enter Drop-off station ID"
                          onChange={async e => {
                            formikForm.handleChange(e);
                            if (e.target.value.length === 24) {
                              setIsDropOffStationExist(true);
                              const selectedStation = await handleStationIdChange(e.target.value);
                              if (selectedStation) {
                                setIsDropOffStationExist(true);
                                formikForm.setFieldValue('dropoffStationName', selectedStation?.name, true);
                                formikForm.setFieldTouched('dropoffStationName', true);
                              } else {
                                setIsDropOffStationExist(false);
                                formikForm.setFieldValue('dropoffStationName', '', true);
                                formikForm.setFieldTouched('dropoffStationName', true);
                              }
                            } else {
                              formikForm.setFieldValue('dropoffStationName', '', true);
                              formikForm.setFieldTouched('dropoffStationName', true);
                            }
                          }}
                        />
                      </FieldWrapper>
                    );
                  }}
                </Field>
                <Field name="dropoffStationName">
                  {({ field, meta: { touched, error } }: FieldProps<'dropoffStationName'>) => {
                    return (
                      <FieldWrapper error={touched && error ? error : ''}>
                        <Text variant="p-medium-bold" mb="spacing-xxs">
                          Drop-off Station name
                        </Text>
                        <Input {...field} name="dropoffStationName" disabled />
                      </FieldWrapper>
                    );
                  }}
                </Field>
                {/* meeting stations */}
                <Field name="meetingPickupStationId">
                  {({ field, meta: { touched, error } }: FieldProps<'meetingPickupStationId'>) => {
                    return (
                      <FieldWrapper error={touched && error ? error : ''}>
                        <Text variant="p-medium-bold" mb="spacing-xxs">
                          Meeting Pickup Station ID
                        </Text>
                        <Input
                          {...field}
                          minLength={24}
                          maxLength={24}
                          name="meetingPickupStationId"
                          placeholder="Enter Meeting Pickup station ID"
                          onChange={async e => {
                            formikForm.handleChange(e);
                            if (e.target.value.length === 24) {
                              setIsMeetingDropOffStationExist(true);
                              const selectedStation = await handleStationIdChange(e.target.value);
                              if (selectedStation) {
                                setIsMeetingDropOffStationExist(true);
                                formikForm.setFieldValue('meetingPickupStationName', selectedStation?.name, true);
                                formikForm.setFieldTouched('meetingPickupStationName', true);
                              } else {
                                setIsMeetingPickUpStationExist(false);
                                formikForm.setFieldValue('meetingPickupStationName', '', true);
                                formikForm.setFieldTouched('meetingPickupStationName', true);
                              }
                            } else {
                              formikForm.setFieldValue('meetingPickupStationName', '', true);
                              formikForm.setFieldTouched('meetingPickupStationName', true);
                            }
                          }}
                        />
                      </FieldWrapper>
                    );
                  }}
                </Field>
                <Field name="meetingPickupStationName">
                  {({ field, meta: { touched, error } }: FieldProps<'meetingPickupStationName'>) => {
                    return (
                      <FieldWrapper error={touched && error ? error : ''}>
                        <Text variant="p-medium-bold" mb="spacing-xxs">
                          Meeting Pickup Station name
                        </Text>
                        <Input {...field} name="meetingPickupStationName" disabled />
                      </FieldWrapper>
                    );
                  }}
                </Field>
                <Field name="meetingDropoffStationId">
                  {({ field, meta: { touched, error } }: FieldProps<'meetingDropoffStationId'>) => {
                    return (
                      <FieldWrapper error={touched && error ? error : ''}>
                        <Text variant="p-medium-bold" mb="spacing-xxs">
                          Meeting Dropoff Station ID
                        </Text>
                        <Input
                          {...field}
                          minLength={24}
                          maxLength={24}
                          name="meetingDropoffStationId"
                          placeholder="Enter Meeting Drop-off station ID"
                          onChange={async e => {
                            formikForm.handleChange(e);
                            if (e.target.value.length === 24) {
                              setIsMeetingDropOffStationExist(true);
                              const selectedStation = await handleStationIdChange(e.target.value);
                              if (selectedStation) {
                                setIsMeetingDropOffStationExist(true);
                                formikForm.setFieldValue('meetingDropoffStationName', selectedStation?.name, true);
                                formikForm.setFieldTouched('meetingDropoffStationName', true);
                              } else {
                                setIsMeetingDropOffStationExist(false);
                                formikForm.setFieldValue('meetingDropoffStationName', '', true);
                                formikForm.setFieldTouched('meetingDropoffStationName', true);
                              }
                            } else {
                              formikForm.setFieldValue('meetingDropoffStationName', '', true);
                              formikForm.setFieldTouched('meetingDropoffStationName', true);
                            }
                          }}
                        />
                      </FieldWrapper>
                    );
                  }}
                </Field>
                <Field name="meetingDropoffStationName">
                  {({ field, meta: { touched, error } }: FieldProps<'meetingDropoffStationName'>) => {
                    return (
                      <FieldWrapper error={touched && error ? error : ''}>
                        <Text variant="p-medium-bold" mb="spacing-xxs">
                          Meeting Drop-off Station name
                        </Text>
                        <Input {...field} name="meetingDropoffStationName" disabled />
                      </FieldWrapper>
                    );
                  }}
                </Field>
                <Field name="group">
                  {({ field, meta: { touched, error } }: FieldProps<'group'>) => {
                    return (
                      <FieldWrapper error={touched && error ? error : ''}>
                        <Text variant="p-medium-bold" mb="spacing-xxs">
                          Employee group
                        </Text>
                        <Select
                          {...field}
                          id="employee-group"
                          name="group"
                          options={groupsOptions()}
                          isLoading={isGroupsLoading}
                          isClearable={false}
                          placeholder="Select group name"
                          onChange={option => {
                            const selectedValue = option as GroupType;
                            if (selectedValue) {
                              formikForm.setFieldValue(field.name, selectedValue, true);
                            }
                          }}
                          required
                        />
                      </FieldWrapper>
                    );
                  }}
                </Field>
                <Field name="number_of_companions">
                  {({ field, meta: { touched, error } }: FieldProps<'number_of_companions'>) => {
                    return (
                      <FieldWrapper error={touched && error ? error : ''}>
                        <Text variant="p-medium-bold" mb="spacing-xxs">
                          Companion Passenger
                        </Text>
                        <Input
                          type="number"
                          min={0}
                          onChange={async e => {
                            formikForm.setFieldValue(field.name, e.target.value, false);
                          }}
                          error={Boolean(touched && error)}
                          placeholder="Number of companions"
                          {...field}
                          id="number_of_companions"
                          name="number_of_companions"
                        />
                      </FieldWrapper>
                    );
                  }}
                </Field>
              </div>
              <footer
                sx={{
                  boxShadow: `inset 0px 1px 0px ${theme.colors.border}`,
                  backgroundColor: 'white',
                  px: 'spacing-s',
                  pt: 'spacing-m',
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                }}
              >
                <Field name="generalError">
                  {({ meta: { error } }: FieldProps<'generalError'>) => {
                    return (
                      <FieldWrapper error={error ? error : ''}>
                        <Text variant="p-medium-bold" mb="spacing-xxs"></Text>
                      </FieldWrapper>
                    );
                  }}
                </Field>
                {formikForm.isSubmitting ? <Spinner color="secondary" width="48px" /> : null}
                <Button
                  type="submit"
                  variant="secondary"
                  sx={{
                    display: 'block',
                    ml: 'auto',
                    mr: 0,
                    mt: 0,
                  }}
                  disabled={!formikForm.isValid || formikForm.isSubmitting}
                >
                  Update Employee
                </Button>
              </footer>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default EditEmployeeForm;
