import { FC, PropsWithChildren, ReactNode } from 'react';
import theme from '@swvl/theme';

type FlagProps = {
  text: string;
  icon: PropsWithChildren<ReactNode>;
  mode: keyof typeof theme.alerts;
  status: keyof typeof theme.alerts['light'];
};

const Flag: FC<FlagProps> = ({ text, mode, status, icon }) => {
  return (
    <div
      sx={{
        p: 'spacing-s',
        borderRadius: 6,
        variant: `alerts.${mode}.${status}`,
        display: 'flex',
        alignItems: 'flex-start',
      }}
    >
      {icon}
      <p
        sx={{
          variant: 'text.p-small',
          m: 0,
          flex: 1,
        }}
      >
        {text}
      </p>
    </div>
  );
};

export default Flag;
