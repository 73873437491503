import { Dispatch, SetStateAction } from 'react';
import { Field, FieldProps } from 'formik';
import Input from '@swvl/input';
import FieldWrapper from '@shared/field-wrapper';
import { fetchStation } from '@customers/employees-profile/resources';

const LocationInformation = ({
  setIsPickUpStationExist,
  setIsDropOffStationExist,
  setIsMeetingPickUpStationExist,
  setIsMeetingDropOffStationExist,
}: {
  setIsPickUpStationExist: Dispatch<SetStateAction<boolean>>;
  setIsDropOffStationExist: Dispatch<SetStateAction<boolean>>;
  setIsMeetingPickUpStationExist: Dispatch<SetStateAction<boolean>>;
  setIsMeetingDropOffStationExist: Dispatch<SetStateAction<boolean>>;
}) => {
  const handleStationIdChange = async (value: string): Promise<{ id: string; name: string }> => {
    try {
      const data = await fetchStation(value);
      return { id: data.id, name: data.name };
    } catch (e) {
      return null;
    }
  };
  return (
    <div>
      <div sx={{ variant: 'text.p-large-bold', mt: 'spacing-m', mb: 'spacing-s' }}>Location information</div>
      <div sx={{ px: 'spacing-xxs' }}>
        {/* home stations */}
        <Field name="pickup_station_id">
          {({ field, meta: { touched, error }, form }: FieldProps<'pickup_station_id'>) => {
            return (
              <FieldWrapper error={touched && error}>
                <Input
                  {...field}
                  minLength={24}
                  maxLength={24}
                  label={<span>Pickup Station ID</span>}
                  name="pickup_station_id"
                  placeholder="Enter Pickup station ID"
                  onChange={async e => {
                    form.handleChange(e);
                    if (e.target.value.length === 24) {
                      const selectedStation = await handleStationIdChange(e.target.value);
                      if (selectedStation) {
                        setIsPickUpStationExist(true);
                        form.setFieldValue('pickupStationName', selectedStation?.name, true);
                        form.setFieldTouched('pickupStationName', true);
                      } else {
                        setIsPickUpStationExist(false);
                        form.setFieldValue('pickupStationName', '', true);
                        form.setFieldTouched('pickupStationName', true);
                      }
                    } else {
                      form.setFieldValue('pickupStationName', '', true);
                      form.setFieldTouched('pickupStationName', true);
                    }
                  }}
                  data-test-id="employee-pickup-station-id"
                />
              </FieldWrapper>
            );
          }}
        </Field>
        <Field name="pickupStationName">
          {({ field, meta: { touched, error } }: FieldProps<'pickupStationName'>) => {
            return (
              <FieldWrapper error={touched && error ? error : ''}>
                <Input {...field} name="pickupStationName" disabled label={<span>Pickup Station name</span>} />
              </FieldWrapper>
            );
          }}
        </Field>
        <Field name="dropoff_station_id">
          {({ field, meta: { touched, error }, form }: FieldProps<'dropoff_station_id'>) => {
            return (
              <FieldWrapper error={touched && error}>
                <Input
                  {...field}
                  label={<span>Dropoff Station ID</span>}
                  minLength={24}
                  maxLength={24}
                  name="dropoff_station_id"
                  placeholder="Enter Drop-off station ID"
                  onChange={async e => {
                    form.handleChange(e);

                    if (e.target.value.length === 24) {
                      const selectedStation = await handleStationIdChange(e.target.value);
                      if (selectedStation) {
                        setIsDropOffStationExist(true);
                        form.setFieldValue('dropoffStationName', selectedStation?.name, true);
                        form.setFieldTouched('dropoffStationName', true);
                      } else {
                        setIsDropOffStationExist(false);
                        form.setFieldValue('dropoffStationName', '', true);
                        form.setFieldTouched('dropoffStationName', true);
                      }
                    } else {
                      form.setFieldValue('dropoffStationName', '', true);
                      form.setFieldTouched('dropoffStationName', true);
                    }
                  }}
                  data-test-id="employee-dropoff-station-id"
                />
              </FieldWrapper>
            );
          }}
        </Field>
        <Field name="dropoffStationName">
          {({ field, meta: { touched, error } }: FieldProps<'dropoffStationName'>) => {
            return (
              <FieldWrapper error={touched && error ? error : ''}>
                <Input {...field} name="dropoffStationName" disabled label={<span>Drop-off Station name</span>} />
              </FieldWrapper>
            );
          }}
        </Field>
        {/* meeting stations */}
        <Field name="meeting_pickup_station_id">
          {({ field, meta: { touched, error }, form }: FieldProps<'meeting_pickup_station_id'>) => {
            return (
              <FieldWrapper error={touched && error}>
                <Input
                  {...field}
                  minLength={24}
                  maxLength={24}
                  label={<span>Meeting Pickup Station ID</span>}
                  name="meeting_pickup_station_id"
                  placeholder="Enter Meeting Pickup station ID"
                  onChange={async e => {
                    form.handleChange(e);
                    if (e.target.value.length === 24) {
                      const selectedStation = await handleStationIdChange(e.target.value);
                      if (selectedStation) {
                        setIsMeetingPickUpStationExist(true);
                        form.setFieldValue('meetingPickupStationName', selectedStation?.name, true);
                        form.setFieldTouched('meetingPickupStationName', true);
                      } else {
                        setIsMeetingPickUpStationExist(false);
                        form.setFieldValue('meetingPickupStationName', '', true);
                        form.setFieldTouched('meetingPickupStationName', true);
                      }
                    } else {
                      form.setFieldValue('meetingPickupStationName', '', true);
                      form.setFieldTouched('meetingPickupStationName', true);
                    }
                  }}
                  data-test-id="employee-pickup-station-id"
                />
              </FieldWrapper>
            );
          }}
        </Field>
        <Field name="meetingPickupStationName">
          {({ field, meta: { touched, error } }: FieldProps<'meetingPickupStationName'>) => {
            return (
              <FieldWrapper error={touched && error ? error : ''}>
                <Input
                  {...field}
                  name="meetingPickupStationName"
                  disabled
                  label={<span>Meeting Pickup Station name</span>}
                />
              </FieldWrapper>
            );
          }}
        </Field>
        <Field name="meeting_dropoff_station_id">
          {({ field, meta: { touched, error }, form }: FieldProps<'meeting_dropoff_station_id'>) => {
            return (
              <FieldWrapper error={touched && error}>
                <Input
                  {...field}
                  label={<span>Meeting Dropoff Station ID</span>}
                  minLength={24}
                  maxLength={24}
                  name="meeting_dropoff_station_id"
                  placeholder="Enter Meeting Drop-off station ID"
                  onChange={async e => {
                    form.handleChange(e);
                    if (e.target.value.length === 24) {
                      const selectedStation = await handleStationIdChange(e.target.value);
                      if (selectedStation) {
                        setIsMeetingDropOffStationExist(true);
                        form.setFieldValue('meetingDropoffStationName', selectedStation?.name, true);
                        form.setFieldTouched('meetingDropoffStationName', true);
                      } else {
                        setIsMeetingDropOffStationExist(false);
                        form.setFieldValue('meetingDropoffStationName', '', true);
                        form.setFieldTouched('meetingDropoffStationName', true);
                      }
                    } else {
                      form.setFieldValue('meetingDropoffStationName', '', true);
                      form.setFieldTouched('meetingDropoffStationName', true);
                    }
                  }}
                  data-test-id="employee-meeting-dropoff-station-id"
                />
              </FieldWrapper>
            );
          }}
        </Field>
        <Field name="meetingDropoffStationName">
          {({ field, meta: { touched, error } }: FieldProps<'meetingDropoffStationName'>) => {
            return (
              <FieldWrapper error={touched && error ? error : ''}>
                <Input
                  {...field}
                  name="meetingDropoffStationName"
                  disabled
                  label={<span>Meeting Drop-off Station name</span>}
                />
              </FieldWrapper>
            );
          }}
        </Field>
      </div>
    </div>
  );
};

export default LocationInformation;
