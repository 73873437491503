import Drawer from '@swvl/drawer';
import { IconButton } from '@swvl/button';
import { CloseIcon } from '@swvl/icons';
import GroupForm from './form';
import { useEmployees } from '@context/employees';
import { instrumentationsHandler } from '@utils/instrumentations';

export const CreateEditDrawer = () => {
  const { handleGroupDrawer, isGroupDrawerOpen } = useEmployees();
  const handleClose = () => {
    handleGroupDrawer(false);
    instrumentationsHandler('click_close_create_group');
  };
  return (
    <div>
      <Drawer closeDrawer={handleClose} isOpen={isGroupDrawerOpen} size={'medium'} anchor={'right'}>
        {/* Close  */}
        <IconButton
          type="button"
          sx={{
            position: 'absolute',
            top: '18px',
            insetInlineEnd: '0',
            zIndex: 'default',
            bg: 'transparent',
            '&:hover, &:active': {
              bg: 'transparent',
            },
          }}
          onClick={handleClose}
          icon={<CloseIcon />}
        />
        <GroupForm />
      </Drawer>
    </div>
  );
};
