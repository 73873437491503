import React, { useMemo, useState } from 'react';
import { useFormikContext, Field, FieldProps } from 'formik';
import { Text } from 'theme-ui';
import { ButtonGroup, ButtonWrapper } from '@swvl/button-group';
import Input from '@swvl/input';
import DefaultPhoneInput from '@swvl/phone-input';
import { Select } from '@swvl/select';
import FieldWrapper from '@shared/field-wrapper';
import { Gender, useGroups } from '@customers/employees-profile/resources';
import { Country, GroupType } from './types';

const PersonalInformation = () => {
  // State
  const [gender, setGender] = useState<Gender>();
  const { setFieldValue, setFieldTouched } = useFormikContext<unknown>();
  const [isValid, setValid] = useState(false);

  const { data: groupsData, isLoading: isGroupsLoading } = useGroups({
    page: 1,
    limit: Number.MAX_SAFE_INTEGER,
  });

  // Memos
  const groupsList = useMemo(
    () =>
      groupsData?.hits.map(group => ({
        label: group.name,
        value: group.id,
      })),
    [groupsData],
  );

  const validatePhone = (value: string, country: Partial<Country>) => {
    if (value.length === 0) {
      setValid(false);
      return 'Mobile number is required';
    } else if (value.length < 7) {
      setValid(false);
      return 'Too short!';
    } else if (!country?.countryCode) {
      setValid(false);
      return 'Start mobile number with country code';
    } else {
      setValid(true);
      return true;
    }
  };

  return (
    <div sx={{ height: '100%' }}>
      <div sx={{ variant: 'text.p-large-bold', mt: 'spacing-m', mb: 'spacing-s' }}>Personal information</div>
      <div sx={{ display: 'flex', height: '100%' }}>
        <div
          sx={{
            flex: 3,
            px: 'spacing-xxs',
            overflowY: 'auto',
            height: '100%',
          }}
        >
          <Field name="name">
            {({ field, meta: { touched, error } }: FieldProps<'name'>) => {
              return (
                <FieldWrapper error={touched && error}>
                  <Input
                    {...field}
                    id="name"
                    name="name"
                    label={<span>Name</span>}
                    value={field.value || ''}
                    placeholder="Enter your name"
                    data-test-id="employee-name"
                    required
                  />
                </FieldWrapper>
              );
            }}
          </Field>
          <div sx={{ mb: '24px' }}>
            <Text variant="text.p-small-medium">Phone number</Text>
            <DefaultPhoneInput
              phoneInputProps={{
                isValid: validatePhone,
                onChange: (value, data: Country) => {
                  const phoneData = { number: value, region_code: data.countryCode };
                  setFieldValue('phone_obj', phoneData, true);
                },
              }}
              id="phone_obj"
              name="phone_obj"
              error={!isValid && isValid !== undefined}
              success={isValid}
            />
          </div>
          <Field name="gender">
            {({ field, meta: { touched, error } }: FieldProps<'gender'>) => {
              return (
                <FieldWrapper error={touched && error ? error : ''}>
                  <Text variant="text.p-small-medium" mb="spacing-xs">
                    Gender
                  </Text>
                  <div sx={{ display: 'flex', flexDirection: 'column' }}>
                    <ButtonGroup>
                      <ButtonWrapper
                        title="Male"
                        active={gender === 'male'}
                        type="button"
                        onClick={() => {
                          setGender('male');
                          setFieldValue(field.name, 'male', true);
                          setFieldTouched(field.name, true);
                        }}
                        sx={{
                          width: '100%',
                        }}
                      >
                        Male
                      </ButtonWrapper>
                      <ButtonWrapper
                        title="Female"
                        active={gender === 'female'}
                        type="button"
                        onClick={() => {
                          setGender('female');
                          setFieldValue(field.name, 'female', true);
                          setFieldTouched(field.name, true);
                        }}
                        sx={{
                          width: '100%',
                        }}
                      >
                        Female
                      </ButtonWrapper>
                    </ButtonGroup>
                  </div>
                </FieldWrapper>
              );
            }}
          </Field>
          <Field name="employee_id">
            {({ field, meta: { touched, error } }: FieldProps<'employee_id'>) => {
              return (
                <FieldWrapper error={touched && error ? error : ''}>
                  <Input
                    {...field}
                    id="employee_id"
                    name="employee_id"
                    label={<span>Employee ID</span>}
                    value={field.value || ''}
                    placeholder="Enter employee id"
                    data-test-id="employee-id"
                  />
                </FieldWrapper>
              );
            }}
          </Field>
          <Field name="group">
            {({ field, meta: { touched, error } }: FieldProps<'group'>) => {
              return (
                <FieldWrapper error={touched && error ? error : ''}>
                  <Text variant="text.p-small-medium" mb="spacing-xxs">
                    Employee group
                  </Text>
                  <Select
                    {...field}
                    id="employee-group"
                    name="group"
                    options={groupsList}
                    isLoading={isGroupsLoading}
                    isClearable={false}
                    placeholder="Select group"
                    onChange={option => {
                      const selectedValue = option as GroupType;
                      if (selectedValue) {
                        setFieldValue('group_id', selectedValue.value, true);
                      }
                    }}
                    required
                    data-test-id="employee-group-list"
                  />
                </FieldWrapper>
              );
            }}
          </Field>
          <Field name="email">
            {({ field, meta: { touched, error } }: FieldProps<'email'>) => {
              return (
                <FieldWrapper error={touched && error ? error : ''}>
                  <Input
                    {...field}
                    id="email"
                    name="email"
                    label={<span>Email address</span>}
                    value={field.value || ''}
                    placeholder="Enter your email address"
                    data-test-id="employee-email"
                  />
                </FieldWrapper>
              );
            }}
          </Field>
          <Field name="number_of_companions">
            {({ field, meta: { touched, error } }: FieldProps<'number_of_companions'>) => {
              return (
                <FieldWrapper error={touched && error ? error : ''}>
                  <Input
                    {...field}
                    id="number_of_companions"
                    name="number_of_companions"
                    min={0}
                    type="number"
                    error={Boolean(touched && error)}
                    label={<span>Companion Passenger</span>}
                    value={field.value || ''}
                    placeholder="Number of companions"
                    data-test-id="number_of_companions"
                  />
                </FieldWrapper>
              );
            }}
          </Field>
        </div>
      </div>
    </div>
  );
};

export default PersonalInformation;
