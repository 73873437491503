import { useState } from 'react';
import { Spinner, Text } from 'theme-ui';
import { Button } from '@swvl/button';
import { useTheme } from '@swvl/theme';
import { useCsvTemplate } from '@bulk-actions/resources';
import { useBulkUpdateEmployees } from './resources';
import { DownloadIcon, UploadIcon, InfoFilledIcon } from '@swvl/icons';
import { saveAs } from 'file-saver';
import { Select } from '@swvl/select';
import FileUpload from '@swvl/file-upload';
import { bulkActionsOptions } from './constants';
import { instrumentationsHandler } from '@utils/instrumentations';
import { useCustomers } from '@context/customers';
import FieldWrapper from '@shared/field-wrapper';
import { useEmployees } from '@context/employees';
import Flag from '@shared/flag';

// Components
const Header = () => {
  return (
    <div sx={{ height: 173 }}>
      <header
        sx={{
          m: 'spacing-m',
          pb: 'spacing-m',
        }}
      >
        <div sx={{ display: 'flex', mb: 'spacing-m' }}>
          <UploadIcon sx={{ marginInlineEnd: 'spacing-xs' }} width={32} height={32} />
          <h5 sx={{ variant: 'text.h5', m: 0, mb: 'spacing-s' }}>Bulk upload</h5>
        </div>
        <Flag
          text="Bulk action capability helps to save time by simultaneously adding / updating multiple items for different use
            cases."
          mode="light"
          status="info"
          icon={<InfoFilledIcon sx={{ marginInlineEnd: 'spacing-s' }} width={24} height={24} />}
        />
      </header>
    </div>
  );
};

const BulkUploadForm = () => {
  // Contexts
  const { theme } = useTheme();
  const { selectedCorporateId } = useCustomers();
  const { handleBulkUpdateDrawer } = useEmployees();

  // States
  const [bulkAction, setBulkAction] = useState<{ label: string; value: string }>();
  const [file, setFile] = useState(null);

  //Resources
  const { mutateAsync: employeesBulkAction } = useBulkUpdateEmployees();

  // handlers
  const handleUploadFile = async () => {
    const formData = new FormData();
    formData.append('document', file.file);
    try {
      await employeesBulkAction({
        corporateId: selectedCorporateId,
        action: bulkAction?.value,
        document: formData,
      });
      instrumentationsHandler('bulk_upload_status', { template_type: 'update-employees', upload_status: 'Success' });
    } catch (e) {
      console.error(e);

      instrumentationsHandler('bulk_upload_status', { template_type: 'update-employees', upload_status: 'Failure' });
    }
    handleBulkUpdateDrawer(false);
  };

  const { data: csvTemplate, isLoading: csvTemplateLoading } = useCsvTemplate({ action: bulkAction?.value });
  // Empty: if true, means that the data will provide only the fields(columns) else columns and rows
  const downloadCSVTemplate = (data: string[], empty: boolean, name: string) => {
    instrumentationsHandler('click_download_template', { template_type: 'update-employees' });
    const newRowRegex = '\r\n';

    const lngLatIndex = data.findIndex(item => item === 'latitude, longitude');
    data[lngLatIndex] = '"latitude, longitude"';
    const columns = !empty ? Object.keys(data[0]).join(',') + newRowRegex : data + newRowRegex;
    const rows = !empty
      ? data.reduce((rows: string, row: string) => {
          return rows.concat(Object.values(row).join(',') + newRowRegex);
        }, '')
      : '';
    const parsedData = columns + rows;
    const csvFile = new File([parsedData], name);
    saveAs(csvFile);
  };
  const getUploadParams = () => ({ url: 'https://httpbin.org/post' });

  return (
    <>
      <Header />
      <div
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          height: `calc(100vh - 145px)`,
          position: 'relative',
        }}
      >
        <div
          sx={{
            px: 'spacing-m',
            paddingBottom: 'spacing-m',
            overflowY: 'auto',
            height: '90%',
          }}
        >
          <FieldWrapper>
            <Text variant="p-medium" mb="spacing-xxs">
              Bulk action
            </Text>
            <Select
              id="bulkactions"
              name="bulkactions"
              options={bulkActionsOptions}
              isClearable
              onChange={option => {
                const selectedValue = option as { label: string; value: string };
                if (selectedValue) {
                  setBulkAction(selectedValue);
                } else {
                  setBulkAction(null);
                }
              }}
              placeholder="Choose bulk action"
              required
              sx={{ width: '100%' }}
            />
          </FieldWrapper>
          {bulkAction?.value ? (
            <div>
              <div>
                <Text variant="p-large-bold" mb="spacing-xxs" sx={{ display: 'block', mb: 12, mt: 32 }}>
                  Download template
                </Text>
                {!csvTemplateLoading && csvTemplate?.fields ? (
                  <div
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      backgroundColor: '#F0F2F6',
                      width: '100%',
                      justifyContent: 'center',
                      height: 56,
                      borderRadius: 6,
                      mb: 32,
                    }}
                    onClick={() => downloadCSVTemplate(csvTemplate?.fields, true, `${bulkAction?.label} template.csv`)}
                  >
                    <DownloadIcon sx={{ marginInlineEnd: 'spacing-xs' }} /> Download employee template
                  </div>
                ) : (
                  <div sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Spinner color="secondary" />
                  </div>
                )}
              </div>
              <div>
                <Text variant="p-large-bold" mb="spacing-xxs" sx={{ display: 'block', mb: 12 }}>
                  Upload
                </Text>
                <FileUpload
                  getUploadParams={getUploadParams}
                  onChangeStatus={(file, status) => {
                    if (status === 'done') {
                      setFile(file);
                    } else if (status === 'removed') {
                      setFile(null);
                    }
                  }}
                  maxSize={1}
                  accept=".csv,text/csv,application/vnd.ms-excel"
                />
              </div>
            </div>
          ) : null}
        </div>
        <footer
          sx={{
            boxShadow: `inset 0px 1px 0px ${theme.colors.border}`,
            backgroundColor: 'white',
            px: 'spacing-s',
            pt: 'spacing-m',
            display: 'flex',
            flexDirection: 'row',
            minHeight: '130px',
            width: '100%',
          }}
        >
          <Button
            variant="secondary"
            sx={{
              display: 'block',
              ml: 'auto',
              mr: 0,
              mt: 0,
            }}
            disabled={file ? false : true}
            onClick={handleUploadFile}
          >
            Upload
          </Button>
        </footer>
      </div>
    </>
  );
};

export default BulkUploadForm;
