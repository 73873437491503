import { useState } from 'react';
import { Spinner, Text } from 'theme-ui';
import { Formik, Form, FormikHelpers, FieldProps, Field } from 'formik';
import { Button } from '@swvl/button';
import { object, string } from 'yup';
import { useTheme } from '@swvl/theme';
import Input from '@swvl/input';
import Popup, { PopupContent, PopupFooter, PopupHeader } from '@swvl/popup';

import { useCustomers } from '@context/customers';
import FieldWrapper from '@shared/field-wrapper';
import { useEmployees } from '@context/employees';
import { GroupProps, useCreateGroup, useUpdateGroup } from '@customers/employees-group/resource';

const formInitialData: GroupProps = {
  name: '',
  description: '',
};

// Validation
export const validationSchema = object().shape({
  name: string()
    .trim()
    .required('You must add name')
    .test('No spaces allowed in name', 'No spaces allowed in name', val => !val?.includes(' ')),
  description: string()
    .trim()
    .required('You must add description')
    .max(150, 'Description must be less than 150 characters'),
});

// Components
const Header = ({ editGroup }: { editGroup?: GroupProps }) => {
  const { theme } = useTheme();
  return (
    <div sx={{ height: '173px' }}>
      <header
        sx={{
          borderBottom: `1px solid ${theme.colors.border}`,
          m: 'spacing-m',
          pb: 'spacing-m',
        }}
      >
        <h5 sx={{ variant: 'text.h5', m: 0, mb: 'spacing-s' }}>{editGroup ? 'Edit' : 'Create'} group</h5>
        <div>Employee Groups enable logical clustering of employees to help in planning employee commute better.</div>
      </header>
      <h6 sx={{ variant: 'text.p-large-bold', m: 'spacing-m' }}>{editGroup ? 'Edit' : 'Add'} employee group</h6>
    </div>
  );
};

const GroupForm = () => {
  // Contexts
  const { theme } = useTheme();
  const { selectedCorporateId } = useCustomers();
  const {
    handleGroupDrawer,
    employeesFilters: { editGroup },
  } = useEmployees();

  // States
  const [isOpen, setOpen] = useState(false);
  const [group, setGroup] = useState<GroupProps>(null);
  // Resources
  const { mutate: createGroup } = useCreateGroup({
    onSuccessCb: () => handleGroupDrawer(false),
  });
  const { mutate: updateGroup } = useUpdateGroup({
    onSettledCb: () => setOpen(false),
  });
  // handlers
  const handleClosePopup = () => setOpen(false);
  const handleUpdateConfirmed = () => {
    updateGroup({ corporateId: selectedCorporateId, ...group });
    handleGroupDrawer(false, editGroup);
  };
  return (
    <>
      <Formik
        initialValues={editGroup ? editGroup : formInitialData}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={true}
        onSubmit={(values: GroupProps, actions: FormikHelpers<GroupProps>) => {
          actions.validateForm(values);
          actions.setSubmitting(true);
          if (editGroup) {
            const { name, description, id } = values;
            setGroup({ name, description, id });
            actions.setSubmitting(false);
            setOpen(true);
          } else {
            createGroup(
              { corporateId: selectedCorporateId, ...values },
              {
                onSettled: () => {
                  actions.setSubmitting(false);
                },
              },
            );
          }
        }}
      >
        {formikForm => (
          <>
            <Header editGroup={editGroup} />
            <Form
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                height: `calc(100vh - 145px)`,
                position: 'relative',
              }}
            >
              <div
                sx={{
                  px: 'spacing-m',
                  paddingBottom: 'spacing-m',
                  overflowY: 'auto',
                  height: '90%',
                }}
              >
                <Field name="name">
                  {({ field, meta: { touched, error } }: FieldProps<GroupProps['name']>) => {
                    return (
                      <FieldWrapper error={touched && error ? error : ''}>
                        <Text variant="p-medium-bold" mb="spacing-xxs">
                          Group name
                        </Text>
                        <Input {...field} id="name" name="name" placeholder="Enter group name" />
                        <Text variant="p-small" mb="spacing-xxs">
                          Group names can’t contain spaces.
                        </Text>
                      </FieldWrapper>
                    );
                  }}
                </Field>
                <Field name="description">
                  {({ field, meta: { touched, error } }: FieldProps<GroupProps['description']>) => {
                    return (
                      <FieldWrapper error={touched && error ? error : ''}>
                        <Text variant="p-medium-bold" mb="spacing-xxs">
                          Group description
                        </Text>
                        <Input {...field} id="description" name="description" placeholder="Enter description" />
                      </FieldWrapper>
                    );
                  }}
                </Field>
              </div>
              <footer
                sx={{
                  boxShadow: `inset 0px 1px 0px ${theme.colors.border}`,
                  backgroundColor: 'white',
                  px: 'spacing-s',
                  pt: 'spacing-m',
                  display: 'flex',
                  flexDirection: 'row',
                  minHeight: '130px',
                  width: '100%',
                }}
              >
                {formikForm.isSubmitting ? <Spinner color="secondary" width="48px" /> : null}
                <Button
                  type="submit"
                  variant="secondary"
                  sx={{
                    display: 'block',
                    ml: 'auto',
                    mr: 0,
                    mt: 0,
                  }}
                  disabled={!formikForm.isValid || formikForm.isSubmitting}
                >
                  {editGroup ? 'Update' : 'Create'} Group
                </Button>
              </footer>
            </Form>
          </>
        )}
      </Formik>
      <Popup isOpen={isOpen} closePopup={handleClosePopup}>
        <PopupHeader>Edit group</PopupHeader>
        <PopupContent>All employees assigned to this group will have their employee group name changed</PopupContent>
        <PopupFooter>
          <Button variant="text_link" onClick={handleClosePopup}>
            Cancel
          </Button>
          <Button variant="secondary" onClick={handleUpdateConfirmed}>
            Update
          </Button>
        </PopupFooter>
      </Popup>
    </>
  );
};

export default GroupForm;
