import Drawer from '@swvl/drawer';
import { IconButton } from '@swvl/button';
import { CloseIcon } from '@swvl/icons';
import EditEmployeeForm from './edit-employee-form';
import BulkUploadForm from './bulk-upload-form';
import AddEmployeeForm from './add-employee-form';
import { useEmployees } from '@context/employees';
import { EMPLOYEE_VIEW } from '@customers/employees-profile/constants';

export const EmployeeDrawer = () => {
  const {
    isEditEmployeeDrawerOpen,
    isBulkUpdateDrawerOpen,
    isAddEmployeeDrawerOpen,
    employeeAction,
    handleEditEmployeeDrawer,
    handleBulkUpdateDrawer,
    handleAddEmployeeDrawer,
  } = useEmployees();

  const handleClose = () => {
    switch (employeeAction) {
      case EMPLOYEE_VIEW.EDIT_EMPLOYEE: {
        return handleEditEmployeeDrawer(false);
      }
      case EMPLOYEE_VIEW.BULK_UPDATE_EMPLOYEES: {
        return handleBulkUpdateDrawer(false);
      }
      case EMPLOYEE_VIEW.ADD_EMPLOYEE: {
        return handleAddEmployeeDrawer(false);
      }
    }
  };

  const isDrawerOpen = () => {
    switch (employeeAction) {
      case EMPLOYEE_VIEW.EDIT_EMPLOYEE: {
        return isEditEmployeeDrawerOpen;
      }
      case EMPLOYEE_VIEW.BULK_UPDATE_EMPLOYEES: {
        return isBulkUpdateDrawerOpen;
      }
      case EMPLOYEE_VIEW.ADD_EMPLOYEE: {
        return isAddEmployeeDrawerOpen;
      }
    }
  };

  const renderEmployeeView = () => {
    switch (employeeAction) {
      case EMPLOYEE_VIEW.EDIT_EMPLOYEE: {
        return <EditEmployeeForm />;
      }
      case EMPLOYEE_VIEW.BULK_UPDATE_EMPLOYEES: {
        return <BulkUploadForm />;
      }
      case EMPLOYEE_VIEW.ADD_EMPLOYEE: {
        return <AddEmployeeForm />;
      }
    }
  };

  return (
    <Drawer closeDrawer={handleClose} isOpen={isDrawerOpen()} size={'large'} anchor={'right'}>
      <IconButton
        type="button"
        sx={{
          position: 'absolute',
          top: 18,
          insetInlineEnd: '0',
          zIndex: 'default',
          bg: 'transparent',
          '&:hover, &:active': {
            bg: 'transparent',
          },
        }}
        onClick={handleClose}
        icon={<CloseIcon />}
      />
      {renderEmployeeView()}
    </Drawer>
  );
};
